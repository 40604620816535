import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import logo from "./images/logo.png";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';
import { withRouter } from "react-router";
import  { Redirect } from 'react-router-dom';
import { Web3StatusContext } from "./contexts/Web3StatusContext";
import axios from 'axios';
import GameItemHelper from './helpers/GameItemHelper'
import NoMatch from './404Page';

const { AccountData, ContractData, ContractForm } = newContextComponents;

class RedirectDetailsPage extends React.Component {
  static contextType = Web3StatusContext;

  state = {
    gameInfo: null,
    wrongPage: false,
  }

  componentDidMount() {
    var entryid = this.props.match.params.entryid;
    GameItemHelper.GetProductDetails(entryid, (info) => {
      this.setState({gameInfo: info});
    }, (errorcode) => {
      if(errorcode === 404) {
        this.setState({wrongPage: true});
      }
    });
  }

  render() {
    var entryid = this.props.match.params.entryid;

    if( this.state.wrongPage ) {
      return (<NoMatch/>);
    }
    else {
      if(this.state.gameInfo == null)
      {
        return "loading...";
      }
      
      var category = this.context.serverdata.categories.find(o => o.categoryID == this.state.gameInfo.typeID);
        
      var typeslug = category.slug.en;
      var productslug = this.state.gameInfo.slug.en;

      return (<Redirect to={`/${typeslug}/${entryid}/${productslug}`}/>);
    }
  };
}

export default withRouter(RedirectDetailsPage);
