import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageEN from './locate/en/translate.json'
import languageJA from './locate/ja/translate.json'
import languageTR from './locate/tr/translate.json'

const detectionOptions = {
    // order and from where user language should be detected
    order: ['cookie', 'localStorage', 'navigator', 'querystring'],
    // cache user language on
    caches: ['localStorage', 'cookie'],
    //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    // only detect languages that are in the whitelist
    checkWhitelist: true,
  };

i18n
.use(XHR)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources: {
        en: languageEN,
        //ja: languageJA,
        tr: languageTR
    },
    /* default language when load the website in browser */
    //lng: "en",
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: ["en", "tr"/*, "ja"*/],
    /* debugger For Development environment */
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        useSuspense: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
    detection: detectionOptions,
    load: 'languageOnly',
})

export default i18n;