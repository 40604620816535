import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import DisplayedWeiData from '../DisplayedWeiData'
import DateComponent from '../DateComponent'
import { Trans } from 'react-i18next';
import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import GetChangeValue from './GetChangeValue'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class WithdrawStoreNFTs extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    stackId: -1,
    id: 0,
    amount: 0,
    to: 0,
  };

  activeAccount = null;

  componentDidMount() {
    const { drizzle } = this.props;
    const contract = drizzle.contracts.ForestStore;
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    const { drizzle } = this.props;
    const contract = drizzle.contracts.ForestStore;

    this.lastBlock = this.context.blockNumber;
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    if(this.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = this.props;
    const { GameForestAssets, ForestStore } = drizzle.contracts;

    var value = this.state.amount;
    value = this.context.web3.utils.toWei(value, "ether");

    const stackId = ForestStore.methods.unstockForestassets.cacheSend
                      (GameForestAssets.address, this.state.id, this.state.amount, this.state.to);
    this.setState({ stackId: stackId });
  }

  myIDChangeHandler = (event) => {
    let { value } = event.target;
    const id = value;
    this.setState({id: id});
  }

  myAmountChangeHandler = (event) => {
    let { value } = event.target;
    const amount = value;
    this.setState({amount: amount});
  }

  myToChangeHandler = (event) => {
    let { value } = event.target;
    const to = value;
    this.setState({to: to});
  }

  render() {

    var buttonCaption = 'Unstock NFTs';

    return (
      <div>
        <h3>Wihhdraw Store NFTs</h3>
        
          <form onSubmit={this.mySubmitHandler}>
            ID:{" "}
            <input
              value={this.state.id}
              onChange={this.myIDChangeHandler}
            />
            <br/>
            Amount:{" "}
            <input
              value={this.state.amount}
              onChange={this.myAmountChangeHandler}
            />
            <br/>
            To:{" "}
            <input
              value={this.state.to}
              onChange={this.myToChangeHandler}
            />
            <br/>
            <input
            type='submit'
            value={buttonCaption}
            />
          </form>
      </div>
    );
  }
};

export default WithdrawStoreNFTs;