import React, { Component } from 'react'
import Identicon from 'identicon.js';
import { Web3StatusContext } from "../contexts/Web3StatusContext";
import eth from '../images/eth.png'
import logo from '../images/logo.png'

class Navbar extends Component {
  static contextType = Web3StatusContext;

  state = { balance: null };

  mounted = false;
  lastBlock = 0;

  updateBlockChainData() {
    if(this.context.activeAccount == null || this.context.web3 == null)
      return;
    this.context.web3.eth.getBalance(this.context.activeAccount, function(err, result) {
      if (err) {
        console.log(err)
      } else {
        if(this.mounted)
          this.setState({balance: this.context.web3.utils.fromWei(result, "ether")});
      }
    }.bind(this));
  }

  componentDidMount() {
    this.updateBlockChainData();

    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    this.updateBlockChainData();

    this.lastBlock = this.context.blockNumber;
  }

  render() {
    var languageDropdown = this.props.radioButtons();
    //TODO enable language
    languageDropdown = '';
    return (
      <nav className="navbar navbar-expand-lg rounded-bottom navBorderBottom" style={{ color: "#55FF55", "backgroundColor": "#1D1D1D" }}>
        {languageDropdown}
          { (this.context.activeAccount != null)
          ? <div className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto">
                <div className="container">
                  <div className="row">
                    <div className="rounded network">
                      <li className="nav-item nav-link small">
                        <b>{this.context.activeChainId}</b>
                      </li>
                    </div>
                    <div className="rounded balance">
                      <li className="nav-item nav-link small">
                        <b>{this.state.balance}</b>
                        <img src={eth} width='18' height='18' alt="eth" />
                      </li>
                    </div>
                    <div className="rounded account">
                      <li className="nav-item nav-link small">
                        {
                          <b>
                          {this.context.activeAccount.substring(0,6) + '...' + this.context.activeAccount.substring(38,42)}
                          </b>
                        }
                        <img
                          alt="id"
                          className="id border border-success"
                          width="20"
                          height="20"
                          src={`data:image/png;base64,${new Identicon(this.context.activeAccount, 30).toString()}`}
                        />
                      </li>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          : <div className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto">
                { this.context.web3
                ? <button
                    type="Success"
                    className="btn btn-outline btn-block "
                    style={{ backgroundColor: "#55FF55", color: "#000000" }}
                    onClick={async () => {
                      try {
                        await window.ethereum.enable()
                      } catch (e) {
                        console.log(e)
                      }
                    }}
                  >
                    L o g i n
                  </button>
                : <button
                    className="btn btn-warning"
                    type="button"
                    onClick={() => {
                      try {
                        window.open("https://metamask.io/")
                      } catch (e) {
                        console.log(e)
                      }
                    }}
                  >
                    Get MetaMask
                  </button>
                }
              </ul>
            </div>
          }
      </nav>
    )
  }
}

export default Navbar