import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Web3StatusContext } from "../contexts/Web3StatusContext";

class DisplayedWeiData extends React.Component {
    static contextType = Web3StatusContext;

  state = { dataKey: null };

  componentDidMount() {
    const { drizzle } = this.props;
    const contract = drizzle.contracts[this.props.contract];
  
    var methodArgs = this.props.methodArgs ? this.props.methodArgs : [];
    const dataKey = contract.methods[this.props.method].cacheCall(...methodArgs);
  
    // save the `dataKey` to local component state for later reference
    this.setState({ dataKey });
  }
  

  // destructure drizzle and drizzleState from props
  render() {
    // get the contract state from drizzleState
    const contract = this.props.drizzleState.contracts[this.props.contract];

    // using the saved `dataKey`, get the variable we're interested in
    const result = contract[this.props.method][this.state.dataKey];
    
    if(result !== undefined) {
      return this.context.web3.utils.fromWei(result.value, "ether");
    }
    
    return '--';
  }
};

export default DisplayedWeiData;