import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from '../TransactionStatus'
import General from '../../helpers/General'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class AddPrize extends React.Component {
  static contextType = Web3StatusContext;

  state = {
    address: '',
    id: 0,
    prizeID: 0,
    stackId: -1,
  };

  componentDidMount() {
    const { drizzle } = this.props;
    this.setState({address: drizzle.contracts.GameForestAssets.address});
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    if(this.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = this.props;
    const contract = drizzle.contracts.ForestStore;

    var stackId;
    
    stackId = contract.methods.addPrize.cacheSend
              (drizzle.contracts.GameForestAssets.address, this.state.id,
                  {prizeContract: this.state.address, prizeID: this.state.prizeID});
    
    
    this.setState({ stackId: stackId });
  }

  myAddressChangeHandler = (event) => {
    let { value } = event.target;
    const address = value;
    this.setState({address: address});
  }

  myIDChangeHandler = (event) => {
    let { value } = event.target;
    const ID = value;
    this.setState({id: ID});
  }

  myPrizeIDChangeHandler = (event) => {
    let { value } = event.target;
    const prizeID = value;
    this.setState({prizeID: prizeID});
  }

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    var disabled = this.context.activeAccount == null;

    var form = (
        <form onSubmit={this.mySubmitHandler}>
            {statusMsg}<br/>
            Main ID
            <input
              value={this.state.id}
              onChange={this.myIDChangeHandler}
            />
            <br/>
            Prize Contract Address
            <input
              value={this.state.address}
              onChange={this.myAddressChangeHandler}
            />
            <br/>
            Prize ID
            <input
              value={this.state.prizeID}
              onChange={this.myPrizeIDChangeHandler}
            />
            <br/>
            <input
            type='submit'
            value="Submit"
            disabled={disabled}
            />
            <br/>
        </form>
      );

    return (
      <div>
        {form}
      </div>
    );
  }
};

export default AddPrize;
