import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import DisplayedWeiData from './DisplayedWeiData'
import DateComponent from './DateComponent'
import { Trans } from 'react-i18next';
import { Web3StatusContext } from "../contexts/Web3StatusContext";
import Loading from './Loading';
import GameItem from './GameItem';
import axios from 'axios';
import GameItemHelper from '../helpers/GameItemHelper'
import contractABI from '../contracts/IERC20Metadata.json'
import ContentHandler from '../helpers/ContentHandler'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class GamesList extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    dataKeys: [],
    saleDataKeys: [],
    userBalanceDataKeys: [],
    lockedUserBalanceDataKeys: [],
    approvedDataKey: null,
    paymentTokenSymbolDataKey: null,
    ids: null,
    gamelist: null,
  };

  componentDidMount() {
    const { drizzle } = this.props;
    const assetContract = drizzle.contracts.GameForestAssets;
    const storeContract = drizzle.contracts.ForestStore;
  
    var dataKeys = [];
    var saleDataKeys = [];
    var userBalanceDataKeys = [];
    var lockedUserBalanceDataKeys = [];
    
    var library = false;
    if(this.props.library !== undefined) {
      library = this.props.library;
    }

    var typeslug = '';
    if(this.props.typeslug !== undefined) {
      typeslug = '/'+this.props.typeslug;
    }
    var listlink = `${ContentHandler.GetServerBaseURL()}products${typeslug}`;
    
    axios.get(listlink)
      .then((response) => {
        var list = response.data;
        var ids = [];
        
        for (let i = 0; i < list.length; i++) {
          ids.push(list[i].productID);
          const dataKey = storeContract.methods.stock.cacheCall(assetContract.address,
            ids[i]);
          dataKeys.push(dataKey);
    
          const saleDataKey = storeContract.methods.saleData.cacheCall(ids[i]);
          saleDataKeys.push(saleDataKey);
    
          const userBalanceDataKey = assetContract.methods.balanceOf.cacheCall(
            this.context.activeAccount, ids[i]);
          userBalanceDataKeys.push(userBalanceDataKey);

          const lockedUserBalanceDataKey = storeContract.methods.getVaultBalance.cacheCall(
            assetContract.address, this.context.activeAccount, ids[i]);
            lockedUserBalanceDataKeys.push(lockedUserBalanceDataKey);
        }

        this.setState({ dataKeys: dataKeys, saleDataKeys: saleDataKeys,
          userBalanceDataKeys: userBalanceDataKeys,
          lockedUserBalanceDataKeys: lockedUserBalanceDataKeys,
          ids: ids, gamelist: list });
      }).catch(function (error) {
        console.log(error);
        if(error.response != undefined)
          console.log(error.response.data);
      });
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

      

    this.lastBlock = this.context.blockNumber;
  }

  render() {
    var dataLoaded = true;
    const GameForestAssets_Contract = this.props.drizzleState.contracts.GameForestAssets;
    const ForestStore_Contract = this.props.drizzleState.contracts.ForestStore;

    var activeColor = "#55FF55";
    var nonActiveColor = "#8B8B8B";

    var gamedetails = [];
    var balances = [];
    var userBalances = [];
    var lockedUserBalances = [];
    var saleDatas = [];
    var colors = [];
    var infoURLs = [];
    var gameInfos = [];
    var gameCategories = [];
    var parameters = {
      gamedetails: gamedetails,
      balances: balances,
      userBalances: userBalances,
      lockedUserBalances: lockedUserBalances,
      saleDatas: saleDatas,
      colors: colors,
      infoURLs: infoURLs,
      gameInfos: gameInfos,
      gameCategories: gameCategories,
    }

    var library = this.props.library !== undefined ? this.props.library : false;
    if(this.state.ids != null) {
      for (let i = 0; i < this.state.ids.length; i++) {
        const balance = ForestStore_Contract["stock"][this.state.dataKeys[i]];
        const saleData = ForestStore_Contract["saleData"][this.state.saleDataKeys[i]];
        const userBalance = GameForestAssets_Contract["balanceOf"][this.state.userBalanceDataKeys[i]];
        const lockedUserBalance = ForestStore_Contract["getVaultBalance"][this.state.lockedUserBalanceDataKeys[i]];
        
        if(balance === undefined || saleData === undefined ||
              userBalance === undefined || lockedUserBalance === undefined) {
          dataLoaded = false;
          break;
        }
        else {
          if(library && userBalance.value <= 0 && lockedUserBalance.value <= 0) {
            continue;
          }
          gamedetails.push(this.state.gamelist[i]);
          balances.push(balance.value);
          userBalances.push(parseInt(userBalance.value));
          lockedUserBalances.push(parseInt(lockedUserBalance.value));
          saleDatas.push(saleData.value);
          if(balance.value > 0)
            colors.push(activeColor);
          else
            colors.push(nonActiveColor);
          
          gameInfos.push(this.state.gamelist[i]);

          var category = this.context.serverdata.categories.find(o => o.categoryID == this.state.gamelist[i].typeID);
          var typeslug = category.slug.en;

          gameCategories.push(category);
          
          infoURLs.push(`/${typeslug}/${this.state.ids[i]}/${this.state.gamelist[i].slug.en}`);
        }
      }
    }

    if(dataLoaded) {
        return (
          <div className="row justify-content-around" style={{ width: '1000px', fontSize: '13px'}}>
          {gamedetails.map((details, key) => {
            var nftid = this.state.ids[key];
                      return(
                        <GameItem
                          drizzle={this.props.drizzle}
                          drizzleState={this.props.drizzleState}
                          parameters={parameters}
                          nftid={nftid}
                          keyIndex={key}
                          key={key}>
                        </GameItem>
                      )
                    })}
          </div>)
      } else {
        if(this.context.activeAccount == null && this.context.web3) {
          return (<button
            type="Success"
            className="btn btn-outline btn-block "
            style={{ backgroundColor: "#55FF55", color: "#000000" }}
            onClick={async () => {
              try {
                await window.ethereum.enable()
              } catch (e) {
                console.log(e)
              }
            }}
          >
            L o g i n
          </button>);
        }
        else {
          return(
            <Loading />
          ) 
        }
    }
  }
};

export default GamesList;