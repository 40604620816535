import ReactTypingEffect from 'react-typing-effect';
import React, { Component } from 'react'
import Identicon from 'identicon.js';
import Loading from './subComponents/Loading'
import GamesList from './subComponents/GamesList'
import { Web3StatusContext } from "./contexts/Web3StatusContext";
import { withRouter } from "react-router";
import NoMatch from './404Page';
import ContentHandler from './helpers/ContentHandler'
import axios from 'axios';

class Store extends Component {
  static contextType = Web3StatusContext;

  state = {
    sponsoredGame: null
  };

  componentDidMount() {
    var listlink = `${ContentHandler.GetServerBaseURL()}product/sponsored`;
    
    axios.get(listlink)
      .then((response) => {
        this.setState({ sponsoredGame: response.data });
      }).catch(function (error) {
        console.log(error);
        if(error.response != undefined)
          console.log(error.response.data);
      });
  }

  render() {
    var dataLoaded = true;

    var library = false;
    if(this.props.library !== undefined) {
      library = this.props.library;
    }
    
    var typeslug = undefined;
    if(this.props.match !== undefined && this.props.match.params.typeslug) {
      typeslug = this.props.match.params.typeslug;

      var category = this.context.serverdata.categories.find(o => o.slug.en == typeslug);
      
      if(category === undefined || category.categoryID >= 10) {
        return (<NoMatch/>);
      }
    }

    if(this.state.sponsoredGame == null) {
      return 'Loading...';
    }

    var promotedEntryID = this.state.sponsoredGame.productID;
    var promotedTitle = this.state.sponsoredGame.name.en;
    var promoimg = ContentHandler.GetImageURL(
      promotedEntryID, 'promo.jpg');

        return (
          <div className="Main" style={{  }}>
            <div className="container-fluid" style={{  }}>
  
            <br></br>
            <div>
              <ReactTypingEffect
                text={[
                  "Welcome to The Game Forest",
                  "Look around and choose the game you like",
                  'Buy and own your game as NFT that you can resell',
                  "Hurry up before all games are sold out!"
                ]}
                speed='40'
                eraseSpeed='10'
                eraseDelay='2000'
                cursorRenderer={cursor => <h1>{cursor}</h1>}
                displayTextRenderer={(text, i) => {
                  return (
                    <h1>
                      {text.split('').map((char, i) => {
                        const key = `${i}`;
                        return (
                          <span
                            key={key}
                            style={i%2 === 0 ? {} : {}}
                          >{char}</span>
                        );
                      })}
                    </h1>
                  );
                }}
              />
              </div>
              <br></br>&nbsp;
              <a href={`/product/${promotedEntryID}`}>
              <img src={promoimg} style={{ width: '1000px', height: '300px' }} alt={promotedTitle}/>
              </a>
              <div className="row">
                <main role="main" className="col-lg-12 d-flex text-center">
                  <div className="content mr-auto ml-auto">
                    <div className="row justify-content-around" style={{ width: '1000px', fontSize: '13px'}}>
  
                    <GamesList
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                      library={library}
                      typeslug={typeslug}
                      >
                    </GamesList>
  
                    </div>
                  </div>
                </main>
              </div>
            </div>
            <br></br>
            <footer>
            
            </footer>
          </div>
        )
  }
}

export default withRouter(Store);