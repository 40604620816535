import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from '../TransactionStatus'
import General from '../../helpers/General'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class GetChangeValue extends React.Component {
  static contextType = Web3StatusContext;

  state = {
    address: '',
    stackId: -1,
    adminAddressDataKey: null,
  };

  getValue() {
    const { drizzle } = this.props;
    const contract = drizzle.contracts[this.props.contractName];

    if(this.props.getterName === undefined) {
      return;
    }

    var adminAddressDataKey;
    
    if(this.props.getterParameter !== undefined) {
      adminAddressDataKey = contract.methods[this.props.getterName].cacheCall(...this.props.getterParameter);
    }
    else
      adminAddressDataKey = contract.methods[this.props.getterName].cacheCall();
    
    this.setState({ adminAddressDataKey: adminAddressDataKey });
  }

  componentDidMount() {
    this.getValue();
  }

  componentDidUpdate(prevProps) {
    if(!General.isEqualArray(prevProps.getterParameter, this.props.getterParameter)) {
      this.getValue();
    }
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    if(this.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = this.props;
    const contract = drizzle.contracts[this.props.contractName];

    var value = this.state.address;
    if(this.props.wei) {
      value = this.context.web3.utils.toWei(value, "ether");
    }
    var stackId;
    if(this.props.setterParameter !== undefined) {
      if(this.props.setterParameterPostfix === undefined ||
          !this.props.setterParameterPostfix) {
            stackId = contract.methods[this.props.setterName].cacheSend
                (this.props.setterParameter, value);
      }
      else {
        stackId = contract.methods[this.props.setterName].cacheSend
                (value, this.props.setterParameter);
      }
    }
    else
      stackId = contract.methods[this.props.setterName].cacheSend
          (value);
    this.setState({ stackId: stackId });
  }

  myAddressChangeHandler = (event) => {
    let { value } = event.target;
    const address = value;
    this.setState({address: address});
  }

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    var disabled = this.context.activeAccount == null;

    var adminAddress = "";
    if(!disabled) {
      const contract = this.props.drizzleState.contracts[this.props.contractName];

      if(this.state.adminAddressDataKey != null) {
        var addressContract = contract[this.props.getterName][this.state.adminAddressDataKey];
        if(addressContract != undefined && addressContract.value != null) {
          
            adminAddress = addressContract.value;
            if(this.props.getterMember !== undefined) {
              if(!Array.isArray(this.props.getterMember)) {
                adminAddress = addressContract.value[this.props.getterMember];
              }
              else {
                adminAddress = '';
                this.props.getterMember.forEach((value, index, array) => {
                  adminAddress += addressContract.value[value].toString() + ',';
                });
              }
            }
            if(this.props.wei) {
              adminAddress = this.context.web3.utils.fromWei(adminAddress, "ether");
            }
          
        }
      }
    }

    var form = '';
    var inputCaption = this.props.inputCaption;
    if(this.props.inputCaption === undefined) {
      inputCaption = "New Value";
    }
    if(this.props.setterName !== undefined) {
      form = (
        <form onSubmit={this.mySubmitHandler}>
            {statusMsg}<br/>
            {inputCaption + " "}
            <input
              value={this.state.address}
              onChange={this.myAddressChangeHandler}
            />
            <br/>
            <input
            type='submit'
            value="Submit"
            disabled={disabled}
            />
            <br/>
        </form>
      );
    }

    return (
      <div>
        {adminAddress.toString()}<br/>
        {form}
      </div>
    );
  }
};

export default GetChangeValue;
