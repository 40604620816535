import React from "react";
import ReactPlayer from 'react-player';

class YoutubeSlide extends React.Component {
  state = { dataKey: null };

  // destructure drizzle and drizzleState from props
  render() {
    return (
        <ReactPlayer width="100%" url={this.props.url} playing={this.props.isSelected} />
      )
  }
};

export default YoutubeSlide;
