import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Web3StatusContext } from "../contexts/Web3StatusContext";
import moment from "moment";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import General from '../helpers/General.js'


const { AccountData, ContractData, ContractForm } = newContextComponents;

class DownloadProgressBar extends React.Component {
  static contextType = Web3StatusContext;
  

  render() {
    if(this.props.progress > 0 && this.props.progress < 100) {
      var variant = "success";
      
      var progressBar = <ProgressBar animated min={0} max={100} now={this.props.progress} variant={variant} />;

      var textElement =
        <small className="justify-content-center d-flex position-absolute w-100 stockProgressText">
          Downloading
        </small>;

      var mainElement =
        <div className="position-relative">
          {textElement}
          
          {progressBar}

        </div>;

      
      return mainElement;
    }
    
    return '';
  }
};

export default DownloadProgressBar;
