import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import DisplayedWeiData from '../DisplayedWeiData'
import DateComponent from '../DateComponent'
import { Trans } from 'react-i18next';
import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import GetChangeValue from './GetChangeValue'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class StoreNFTBalance extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    tokenID: 1
  };

  activeAccount = null;

  componentDidMount() {
    const { drizzle } = this.props;
    const contract = drizzle.contracts.ForestStore;
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    const { drizzle } = this.props;
    const contract = drizzle.contracts.ForestStore;

    this.lastBlock = this.context.blockNumber;
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    this.setState({tokenID: this.state.tokenID});
  }

  myAmountChangeHandler = (event) => {
    let { value } = event.target;
    const tokenID = value;
    this.setState({tokenID: tokenID});
  }

  render() {

    var buttonCaption = 'Get store balance';

    return (
      <div>
        <h3>NFT Balance</h3>
        
          <form onSubmit={this.mySubmitHandler}>
            Token ID:{" "}
            <input
              value={this.state.tokenID}
              onChange={this.myAmountChangeHandler}
            />
          </form>

          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="stock"
            getterParameter={[this.props.drizzle.contracts.GameForestAssets.address, this.state.tokenID]}
            contractName="ForestStore">
        </GetChangeValue>
      </div>
    );
  }
};

export default StoreNFTBalance;