import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from "../TransactionStatus";
import { Trans } from 'react-i18next';
import GetChangeValue from './GetChangeValue'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class PriceSubmissionForm extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    gameID: 0,
    price: 0,
    stackId: -1,
  };
  
  componentDidMount() {
    const { drizzle } = this.props;
    
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    this.lastBlock = this.context.blockNumber;
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    const { GameForestAssets, ForestStore } = this.props.drizzle.contracts;

    var price = this.context.web3.utils.toWei(
                  this.state.price.toString(), "ether");
    const stackId = ForestStore.methods.setPrice.cacheSend(
          this.state.gameID,
          price);
    this.setState({stackId: stackId});
  }

  isNumber(n) {
    return !isNaN(n) && isFinite(n);
  }

  myGameIDChangeHandler = (event) => {
    var gameID = parseInt(event.target.value);
    if (!this.isNumber(gameID)) {
      gameID = this.state.gameID;
    }
    
    this.setState({gameID: gameID});
  }

  myPriceChangeHandler = (event) => {
    var price = parseFloat(event.target.value);
    if (!this.isNumber(price)) {
      price = this.state.price;
    }
    
    this.setState({price: price});
  }

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    // get the contract state from drizzleState
    const { GameForestAssets, ForestStore } = this.props.drizzleState.contracts;
    
    var buttonCaption = this.context.i18n.t('payment_form.button_caption');

    var form = '';
    if(this.context.activeAccount == null) {
      form = <span><Trans>payment_form.connect_account</Trans><br/>
              <button onClick={this.context.connect}>
              <Trans>connect_account</Trans>
              </button>
            </span>;
    }
    else {
      var submitButtonEnabled = true;
      
      var increase_text = (<span style={{ color: 'red' }}>
            <Trans>payment_form.increasing_price</Trans>
            </span>);
      increase_text = '';//remove this line for increasing prices
      form = (
        <form onSubmit={this.mySubmitHandler}>
          Game ID: <input
            type="number"
            step={1}
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.gameID}
            onChange={this.myGameIDChangeHandler}
          />
          <br/>
          Price: <input
            type="number"
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.price}
            onChange={this.myPriceChangeHandler}
          />
          <br/>

          <input
            type='submit'
            disabled={!submitButtonEnabled}
            value={buttonCaption}
          />
        </form>
      );
    }

    

    return (
      <div><Trans>payment_form.current_price</Trans><br/>
        <br/>
        <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="saleData"
            getterParameter={[this.state.gameID]}
            getterMember="price"
            contractName="ForestStore"
            wei={true}>
        </GetChangeValue>

        {statusMsg}
        <br/>
        {form}

      </div>
      
    );
  }
};

export default PriceSubmissionForm;
