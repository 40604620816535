import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Trans } from 'react-i18next';

const { AccountData, ContractData, ContractForm } = newContextComponents;

class TransactionStatus extends React.Component {
  state = { dataKey: null };

  componentDidMount() {
    
  }
  

  // destructure drizzle and drizzleState from props
  render() {
    // get the contract state from drizzleState
    const contract = this.props.contract;
    
    var statusMsg = '';
    if (this.props.stackId >= 0) {
      if(this.props.drizzleState.transactionStack[this.props.stackId]) {
        const txHash = this.props.drizzleState.
                            transactionStack[this.props.stackId];
        if(this.props.drizzleState.transactions[txHash] !== undefined) {
          statusMsg = this.props.drizzleState.transactions[txHash].status;
          statusMsg = (<span style={{ color: 'red' }}>{statusMsg}</span>);
        }
        else
          return <Trans>processing</Trans>;
      }
    }
    
    return statusMsg;
  }
};

export default TransactionStatus;