import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import DisplayedWeiData from './DisplayedWeiData'
import DateComponent from './DateComponent'
import { Trans } from 'react-i18next';
import { Web3StatusContext } from "../contexts/Web3StatusContext";
import Loading from './Loading';
import GameItemHelper from '../helpers/GameItemHelper'
import axios from 'axios';
import ContentHandler from '../helpers/ContentHandler'
import DownloadProgressBar from './DownloadProgressBar'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class GameItem extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    stackId: 0,
    paymentContractAddressDataKey: null,
    approvedDataKey: null,
    paymentTokenSymbolDataKey: null,
    downloadProgress: 0,
  };

  componentDidMount() {
    const { drizzle } = this.props;
    const storeContract = drizzle.contracts.ForestStore;

    var key = this.props.keyIndex;

    const paymentContractAddressDataKey = storeContract.methods.
              paymentToken.cacheCall(this.props.nftid);

    this.setState({paymentContractAddressDataKey: 
      paymentContractAddressDataKey});
  }

  componentDidUpdate() {
    GameItemHelper.UpdateApprovalAndSymbol(this);
  }

  buyClicked = (id, paymentTokenZero, price, event) => {
    event.preventDefault();
    GameItemHelper.buy(this, id, paymentTokenZero, price);
  }

  approveClicked = (paymentContractAddress, amount, event) => {
    event.preventDefault();
    
    GameItemHelper.approve(this, paymentContractAddress, amount)
  }

  withdrawClicked = (id, amount, event) => {
    event.preventDefault();
    
    GameItemHelper.withdraw(this, id, amount)
  }

  downloadClicked = (nftid, event) => {
    event.preventDefault();
    var key = this.props.keyIndex;
    GameItemHelper.callDownloadAPI(this, nftid, this.props.parameters.gameInfos[key].filename);
  }

  render() {
    const ForestStore_Contract = this.props.drizzleState.contracts.ForestStore;
    
      var key = this.props.keyIndex;
      var nftid = this.props.nftid;

      var copies = this.props.parameters.userBalances[key]
          + this.props.parameters.lockedUserBalances[key];
      var stock = this.props.parameters.balances[key];

      var saleData = this.props.parameters.saleDatas[key];

      var allowance = undefined;
    var paymentTokenSymbol = undefined;

    var buyButton = '';
    var warning = '';
    var countdown = '';
    var unlockWarning = '';
    var paymentContractAddressIsZero = null;

    const paymentContractAddressContract = ForestStore_Contract["paymentToken"][this.state.paymentContractAddressDataKey];
    if(paymentContractAddressContract !== undefined) {
      paymentContractAddressIsZero = this.context.web3.utils.toBN(paymentContractAddressContract.value).isZero();
    }
    
    if(this.state.approvedDataKey != null) {
      allowance = this.props.drizzleState.contracts[paymentContractAddressContract.value]
              ["allowance"][this.state.approvedDataKey];
      paymentTokenSymbol = this.props.drizzleState.contracts[paymentContractAddressContract.value]
              ["symbol"][this.state.paymentTokenSymbolDataKey];
    }

    if(allowance !== undefined && paymentTokenSymbol !== undefined) {
      allowance = allowance.value;
      paymentTokenSymbol = paymentTokenSymbol.value;
    }

    

      
    if(paymentContractAddressIsZero ||
        (allowance !== undefined && paymentTokenSymbol !== undefined)) {
      
          if(paymentTokenSymbol === undefined) {
            paymentTokenSymbol = "Matic";
          }

      var saleDataDisplay = GameItemHelper.GetProductDisplayVariables(saleData,
        this, paymentTokenSymbol);

        var priceWei = saleDataDisplay.priceWei;      

        var buyFunction = this.buyClicked.bind(this, nftid,
          paymentContractAddressIsZero, priceWei);

          var approveFunction = this.approveClicked.bind(this,
            paymentContractAddressContract.value,
            priceWei);

      buyButton = GameItemHelper.BuyButton(this,
        allowance, saleDataDisplay,
        buyFunction, approveFunction, paymentTokenSymbol, stock, copies, nftid,
        paymentContractAddressIsZero);
        
        var normalPrice = saleDataDisplay.priceDisplay
        var discountedPrice = saleDataDisplay.discountedPriceDisplay;
        var discountedRatio = saleDataDisplay.discountedRatio;
        var discountWarning = saleDataDisplay.discountWarningSmall;
        var discountCountDown = saleDataDisplay.discountCountDown;

        unlockWarning = saleDataDisplay.unlockWarning;

        var saleStart = saleDataDisplay.saleStartSmall;
        var saleStartCountDown = saleDataDisplay.saleStartCountDown;

        warning = saleStart;
        countdown = saleStartCountDown;

        if(saleDataDisplay.saleStarted) {
          warning = discountWarning;
          countdown = discountCountDown;
        }
    }

      

      

      
      
      var downloadFunction = this.downloadClicked.bind(this, nftid);
      var withdrawFunction = this.withdrawClicked.bind(this, nftid, this.props.parameters.lockedUserBalances[key]);

      var downloadButton = GameItemHelper.DownloadButton(this, copies, downloadFunction);

      

      var thumbnail = ContentHandler.GetImageURL(
        nftid, 'thumbnail.jpg');

      
        

    var details = this.props.parameters.gamedetails[key];
    var title = details.name.en;
    var developer = details.developer;

    return (
        <div className="p-3 item" key={key}>
        
        <div>
        <a href={this.props.parameters.infoURLs[key]} rel="noopener noreferrer">
            <img src={thumbnail} className="thumbnail" style={{ borderColor: this.props.parameters.colors[key] }} alt={title}/>
        </a>
        <div className="belowThumbnail">
          {discountedRatio}
          <div className="detailsDeveloperContainer">
            {developer}

            <a href={this.props.parameters.infoURLs[key]} rel="noopener noreferrer">
              <h3>{title}</h3>
            </a>
          </div>
        </div>
        </div>

        
        <div className="price">
          <div className="priceLeft disabledColor">Price: </div>
          <div className="priceRight">
            {discountedPrice}
            {normalPrice}
          </div>
        </div>
        <div className="afterPrice">
          {warning}
          {countdown}
          {downloadButton}
          <DownloadProgressBar
          progress={this.state.downloadProgress}
        ></DownloadProgressBar>
          {buyButton}
        </div>
        </div>
      )
  }
};

export default GameItem;