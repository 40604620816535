import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from '../TransactionStatus'

const { AccountData, ContractData, ContractForm } = newContextComponents;
//https://docs.openzeppelin.com/contracts/3.x/api/access#AccessControl-grantRole-bytes32-address-
class WhitelistForestAssets extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    address: '',
    stackId: -1,
    grantCheck: true,
  };

  componentDidMount() {
    const { drizzle } = this.props;
    const GameForestAssets = drizzle.contracts.GameForestAssets;
  }

  componentWillUpdate() {
    
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    if(this.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = this.props;
    const { ForestStore } = drizzle.contracts;

    var method = "approveForestAssetsContract";

    const stackId = ForestStore.methods[method].cacheSend
        (this.state.address, this.state.grantCheck);
    this.setState({ stackId: stackId });
  }

  myAddressChangeHandler = (event) => {
    let { value } = event.target;
    const address = value;
    this.setState({address: address});
  }

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    var disabled = this.context.activeAccount == null;

    var loaded = true;

    if(!loaded) {
      return "loading...";
    }

    var form = (
    <form onSubmit={this.mySubmitHandler}>
        {statusMsg}<br/>
        <br/>
        Address:{" "}
        <input
          value={this.state.address}
          onChange={this.myAddressChangeHandler}
        />
        <br/>
        Check for grant, uncheck revoke:{" "}
        
        <input type="checkbox" defaultChecked={this.state.grantCheck}
            onChange={this.handleCheck.bind(this)} name="grantCheck"/>
        <br/>
        <input
        type='submit'
        value="Submit"
        disabled={disabled}
        />
        <br/>
    </form>
    );

    return (
      <div>
        {form}
      </div>
    );
  }
};

export default WhitelistForestAssets;
