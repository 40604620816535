import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from '../TransactionStatus'

const { AccountData, ContractData, ContractForm } = newContextComponents;
//https://docs.openzeppelin.com/contracts/3.x/api/access#AccessControl-grantRole-bytes32-address-
class AddRevokeRole extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    address: '',
    stackId: -1,
    grantCheck: true,
    adminAddressCountDataKey: null,
    adminAddressDataKeys: null,
  };

  componentDidMount() {
    const { drizzle } = this.props;
    const contract = drizzle.contracts.GameForestAssets;

    var role = this.context.web3.utils.keccak256(this.props.role);
    if(contract.methods["getRoleMemberCount"] !== undefined) {
      const adminAddressCountDataKey = contract.methods["getRoleMemberCount"].cacheCall(role);
      
      this.setState({ adminAddressCountDataKey: adminAddressCountDataKey });
    }
  }

  componentWillUpdate() {
    
  }

  componentDidUpdate() {
    const { GameForestAssets } = this.props.drizzleState.contracts;
    
    if(GameForestAssets["getRoleMemberCount"] !== undefined) {
      var addressCountContract = GameForestAssets["getRoleMemberCount"]
                                [this.state.adminAddressCountDataKey];
      if(addressCountContract != undefined) {
        if(addressCountContract.value >= 0) {
          var addressCount = addressCountContract.value;
          
          if(this.state.adminAddressDataKeys == null) {
            var adminAddressDataKeys = [];
            let i = 0;
            for(i = 0; i < addressCount; i++) {
              const contract = this.props.drizzle.contracts.GameForestAssets;

              var role = this.context.web3.utils.keccak256(this.props.role);
              const adminAddressCountDataKey = contract.methods["getRoleMember"].
                                                cacheCall(role, i);
              adminAddressDataKeys.push(adminAddressCountDataKey);
            }
            this.setState({ adminAddressDataKeys: adminAddressDataKeys });
          }
        }
      }
    }

    if(this.lastBlock == this.context.blockNumber)
      return;

    this.lastBlock = this.context.blockNumber;
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    if(this.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = this.props;
    const { GameForestAssets } = drizzle.contracts;

    var role = this.context.web3.utils.keccak256(this.props.role);
    var method = "revokeRole";
    if(this.state.grantCheck) {
      method = "grantRole";
    }

    const stackId = GameForestAssets.methods[method].cacheSend
        (role, this.state.address);
    this.setState({ stackId: stackId });
  }

  myAddressChangeHandler = (event) => {
    let { value } = event.target;
    const address = value;
    this.setState({address: address});
  }

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    var disabled = this.context.activeAccount == null;

    var adminAddress = "";
    var loaded = true;
    if(!disabled) {
      const { GameForestAssets } = this.props.drizzleState.contracts;

      if(GameForestAssets["getRoleMemberCount"] !== undefined) {
        var addressCountContract = GameForestAssets["getRoleMemberCount"]
                                  [this.state.adminAddressCountDataKey];
        if(addressCountContract != undefined) {
          if(addressCountContract.value >= 0) {
            var adminAddressCount = addressCountContract.value;
            
            if(this.state.adminAddressDataKeys == null) {
              return "loading...";
            }
            
            let i = 0;
            for(i = 0; i < adminAddressCount; i++) {
              var roleContract = GameForestAssets["getRoleMember"]
                                    [this.state.adminAddressDataKeys[i]];
              if(roleContract === undefined) {
                loaded = false;
                break;
              }
              adminAddress = adminAddress + roleContract.value;
            }
          }
        }
      }
    }

    if(!loaded) {
      return "loading...";
    }

    var form = (
    <form onSubmit={this.mySubmitHandler}>
        {statusMsg}<br/>
        {adminAddress}
        <br/>
        Address:{" "}
        <input
          value={this.state.address}
          onChange={this.myAddressChangeHandler}
        />
        <br/>
        Check for grant, uncheck revoke:{" "}
        
        <input type="checkbox" defaultChecked={this.state.grantCheck}
            onChange={this.handleCheck.bind(this)} name="grantCheck"/>
        <br/>
        <input
        type='submit'
        value="Submit"
        disabled={disabled}
        />
        <br/>
    </form>
    );

    return (
      <div>
        {form}
      </div>
    );
  }
};

export default AddRevokeRole;
