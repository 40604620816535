import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from '../TransactionStatus'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class EmergencySuicide extends React.Component {
  static contextType = Web3StatusContext;

  state = {
    stackId: -1,
  };

  mySubmitHandler = (event) => {
    event.preventDefault();
    if(this.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = this.props;
    const { ForestStore } = drizzle.contracts;

    const stackId = ForestStore.methods.destroy.cacheSend();
    this.setState({ stackId: stackId });
  }

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    var disabled = this.context.activeAccount == null;

    var form = (
    <form onSubmit={this.mySubmitHandler}>
        {statusMsg}<br/>
        <input
        type='submit'
        value="Suicide"
        disabled={disabled}
        />
    </form>
    );

    return (
      <div>
        {form}
      </div>
    );
  }
};

export default EmergencySuicide;
