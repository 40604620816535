import Web3 from "web3";
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Store from './Store';
import Navbar from './subComponents/Navbar';
import Admin from './Admin';
import Details from './Details';
import RedirectDetailsPage from './RedirectDetailsPage';
import NoMatch from './404Page';
import { Web3StatusContext } from "./contexts/Web3StatusContext";

import { DrizzleContext } from "@drizzle/react-plugin";
import { Drizzle } from "@drizzle/store";
import drizzleOptions from "./drizzleOptions";
import Web3Modal from "web3modal";
import { withTranslation, Trans } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import WalletConnectProvider from "@walletconnect/web3-provider";
import axios from 'axios';

import ContentHandler from './helpers/ContentHandler'

var supportedChains = [137, "0x89"/*, 1, "0x1"*/];
if(window.location.host.startsWith("test")) {
  supportedChains = [5777, "0x1691", 3, "0x3", 4, "0x4", 5, "0x5", 97, "0x61", 1337, "0x539", 80001, "0x13881"];
}
else if(window.location.host.startsWith("localhost")) {
  supportedChains = [137, "0x89"/*, 1, "0x1"*/, 5777, "0x1691", 3, "0x3", 4, "0x4", 5, "0x5", 97, "0x61", 1337, "0x539", 80001, "0x13881"];
}

class Main extends React.Component {
  static contextType = Web3StatusContext;

  state = {drizzle : null, mainError: null};
  drizzle = null;
  web3 = null;
  _provider = null;
  mounted = false;

  countryLangMap = {};
  langCountryMap = {};

  constructor(props) {
    super(props)
    
    this.countryLangMap = {'US': 'en', 'TR': 'tr', 'JP': 'ja'};
    this.langCountryMap = {};

    for (var key in this.countryLangMap) {
      if (this.countryLangMap.hasOwnProperty(key)) {
        this.langCountryMap[this.countryLangMap[key]] = key;
      }
    }
  }

  init() {
    drizzleOptions.init(drizzleOptions);

    if(typeof drizzleOptions.web3.customProvider == "string") {
      if(this.context.web3error != null) {
        return;
      }
      this.context.setConnected(false);
      this.context.setWeb3error(drizzleOptions.web3.customProvider);
    }
    else {
      
      if(this.context.web3 != null) {
        return;
      }
      this.context.setConnected(true);
      this.context.setWeb3error(null);
      this.web3 = drizzleOptions.web3.customProvider;
      this.context.setWeb3(this.web3);
      this._provider = this.web3.currentProvider;
    }
  }

  reinitDrizzle() {
    this.drizzle = null;
    this.setState({drizzle: this.drizzle});
    this.initDrizzle();
  }

  initDrizzle() {
    if(this.context.web3error == null &&
      this.context.chainIDerror == null) {
        if(this.drizzle == null) {
          
          this.drizzle = new Drizzle(drizzleOptions);
          this.setState({drizzle: this.drizzle});
        }
      }
      else {
        
        if(this.drizzle != null) {
          this.drizzle = null;
          this.setState({drizzle: null});
          return;
        }
      }
  }

  subscribeEvents() {
    if(typeof this._provider === 'undefined' || this._provider == null)
      return;
    
    this._provider.on("accountsChanged", (accounts) => {
      console.log("accountsChanged");
      //accounts: string[]
      console.log(accounts);
      this.checkAccount(accounts);
    });
    
    // Subscribe to chainId change
    this._provider.on("chainChanged", (chainId) => {
      //chainId: number
      this.checkChainID(chainId);
      console.log(chainId);
    });
    
    // Subscribe to provider connection
    this._provider.on("connect", (info) => {
      //chainId: number
      console.log(info);
      if(!this.checkChainID(info.chainId)) {
        this.context.setConnected(false);
      }
      else {
        this.context.setConnected(true);
      }
    });
    
    // Subscribe to provider disconnection
    this._provider.on("disconnect", (error) => {
      this.context.setConnected(false);
      //error: { code: number; message: string }
      console.log(error);
    });

    this._provider.on('message', (message) => {
      
    });

    this.web3.eth.net.getId().then((chainId) => {
      this.checkChainID(chainId);
    });

    this.web3.eth.getAccounts().then((accounts) => {
      this.checkAccount(accounts);
    });
  }

  unsubscribeEvents() {
    if(typeof this._provider === 'undefined' || this._provider == null)
      return;

    this._provider.removeAllListeners("accountsChanged");
    
    // Subscribe to chainId change
    this._provider.removeAllListeners("chainChanged");
    
    // Subscribe to provider connection
    this._provider.removeAllListeners("connect");
    
    // Subscribe to provider disconnection
    this._provider.removeAllListeners("disconnect");

    this._provider.removeAllListeners('message');
  }

  componentDidMount() {
    this.context.setConnect(this.connect.bind(this));
    this.context.seti18n(this.props.i18n);

    var component = this;
    var listlink = `${ContentHandler.GetServerBaseURL()}categories`;
    axios.get(listlink)
      .then((response) => {
        var list = response.data;
        this.context.setServerData({categories:list,
                ...this.context.serverdata});
      }).catch(function (error) {
        console.log(error);
        if(error.response !== undefined)
          console.log(error.response.data);
          component.setState({mainError: (<div style={{color:"red"}}>
          Error listing games, please refresh the page.
        </div>)}) 
      });

    this.init();
    this.subscribeEvents();

    var lang = this.props.i18n.languages[0];
    var country = this.langCountryMap[lang];
    
    this.setState({value: country});

    this.mounted = true;
  }

  componentDidUpdate() {
    if(!this.mounted)
      return;
    this.init();
  }

  componentWillUnmount() {
    this.unsubscribeEvents();
  }

  connectMatic() {
    const MATIC_MAINNET_PARAMS = {
      chainId: '0x89',
      chainName: 'Polygon',
      nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18
      },
      rpcUrls: ['https://polygon-rpc.com/'],
      blockExplorerUrls: ['https://polygonscan.com']
    }
  
    this.web3.currentProvider
        .request({
          method: 'wallet_addEthereumChain',
          params: [MATIC_MAINNET_PARAMS]
        })
        .catch((error) => {
          console.log(error)
        })
  }

  checkChainID(chainId) {
    this.context.setActiveChainId(chainId);
    var isSupported = supportedChains.includes(chainId);
    console.log('15 chain id ' + chainId + ' isSupported ' + isSupported);
    if(!isSupported) {
      this.context.setChainIDerror(
        (
          <div>
            Un supported chain! Switch to Polygon network on Meta Mask
            <br/>
            <button onClick={this.connectMatic.bind(this)}>
              Switch to Polygon Network
            </button>
          </div>
        )
      );
      console.log('unsupported chain!');
    }
    else {
      this.context.setChainIDerror(null);
    }
    this.initDrizzle();
    return true;
  }

  checkAccount(accounts) {
    var account = null;
    var accountChanged = false;
    if(this.context.web3 == null)
      return;
    if(accounts.length > 0)
      account = this.context.web3.utils.toChecksumAddress(accounts[0]);
    
    accountChanged = this.context.activeAccount != account;
    this.context.setActiveAccount(account);
    var isSupported = true;
    
    if(!isSupported) {
      this.context.setAccounterror('account not connected!');
      console.log('account not connected');
    }
    else {
      this.context.setAccounterror(null);
    }
    if(accountChanged) {
      this.reinitDrizzle();
    }
    return true;
  }

  async connectWeb3Modal(drizzle, drizzleState) {
    const providerOptions = {
      // Example with injected providers
      /*injected: {
        network: drizzleOptions.network,
        package: null
      },*/
      "window.eth": {
        display: {
          logo: "data:image/gif;base64,INSERT_BASE64_STRING",
          name: "Browser Provider",
          description: "Connect to your Browser provider"
        },
        connector: async (ProviderPackage, options) => {
          
            return this.web3;
        }
      },
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          rpc: {
            137: "https://polygon-rpc.com/",
          },
          network: 'Polygon',
          chainId: 137,
          infuraId: ""
        }
      }    
    };

    const web3Modal = new Web3Modal({
      //network: "binance", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    web3Modal.connect().then(function(_provider) {
      const web3 = new Web3(_provider);
      drizzleOptions.web3.customProvider = web3;
      this.init();
      this.subscribeEvents();
    }.bind(this));
  }

  async connectWalletConnect(drizzle, drizzleState) {
    const provider = await new WalletConnectProvider({
      rpc: {
        137: "https://polygon-rpc.com/",
      },
      chainId: 137,
      network: "Polygon"
    });
    /*await provider.disconnect();
    await provider.wc.createSession({
      chainId: 56
    });*/

    await provider.enable();
    const web3 = await new Web3(provider);
    drizzleOptions.web3.customProvider = web3;
    this.init();
    this.subscribeEvents();
  }

  async connect(drizzle, drizzleState) {
    console.log('connect');
    await this.connectWalletConnect();
  }

  onLanguageHandle = (event) => {
    var country = event;
    var lang = this.countryLangMap[country];
    this.setState({value: country})
    this.props.i18n.changeLanguage(lang)
  }
 
  renderRadioButtons = (value) => {
    return (
      <div style={{color: 'rgb(85, 255, 85)', backgroundColor: 'rgb(29, 29, 29)'}}>
        
        <ReactFlagsSelect
          selected={value}
          onSelect={(e) => this.onLanguageHandle(e)}
          countries={["US", "TR"/*, "JP"*/]}
          customLabels={{
            "US": { primary: "English" },
          }}
          placeholder="Select Language"
          fullWidth={false}
          
        />
      </div>
    )
  }

  render() {
    if(this.context.web3error != null) {
      var error = this.context.web3error;
      return (
        <div style={{ textAlign: 'center'}}>
          <div>
            {error}
          </div>
          <div>
            <button onClick={this.connect.bind(this)}>
              Connect Wallet
            </button>
          </div>
        </div>
      );
    }

    if(this.state.mainError != null) {
      return this.state.mainError;
    }
    
    if(this.context.chainIDerror != null) {
      var error = this.context.chainIDerror;
      return error;
    }
    
    if(this.state.drizzle == null) {
      return (<div><Trans>loading</Trans></div>);
    }

    return (
      <DrizzleContext.Provider drizzle={this.state.drizzle}>
        <DrizzleContext.Consumer>
          
          {drizzleContext => {
            const { drizzle, drizzleState, initialized } = drizzleContext;
            
            if (!initialized || !this.context.serverInitialized()) {
              return <Trans>loading</Trans>;
            }

            if(this.context.accounterror != null) {
              var error = this.context.accounterror;
              return error;
            }

            return (
              <div className="App">
                <Navbar radioButtons={this.renderRadioButtons.bind(this, this.state.value)}/>
                
                <Switch> {/* The Switch decides which component to show based on the current URL.*/}
                  <Route exact path="/"
                    render={() => {return (
                      <div>
                        <Store drizzle={drizzle}
                        drizzleState={drizzleState} />
                      </div>
                    );}}></Route>
                  <Route exact path="/library"
                    render={() => (
                      <div>
                        <Store drizzle={drizzle}
                        drizzleState={drizzleState}
                        library={true}
                        />
                      </div>
                    )}></Route>
                  <Route path='/admin' render={() => (
                        <Admin drizzle={drizzle}
                        drizzleState={drizzleState} />
                    )}></Route>
                  <Route path='/:typeslug/:entryid/:slug' render={() => {
                    return (
                      <Details drizzle={drizzle}
                      drizzleState={drizzleState}
                      />)
                  }}></Route>
                  <Route path='/product/:entryid' render={() => {
                    return (
                        <RedirectDetailsPage drizzle={drizzle}
                        drizzleState={drizzleState}
                        />
                    )}}></Route>
                  <Route path='/:typeslug' render={() => {
                    return (
                        <Store drizzle={drizzle}
                        drizzleState={drizzleState}/>
                    )}}></Route>
                  <Route path="*" render={() => (
                        <NoMatch drizzle={drizzle}
                        drizzleState={drizzleState} />
                    )}>
                  </Route>
                </Switch>
              </div>
            )
          }}
          
        </DrizzleContext.Consumer>
      </DrizzleContext.Provider>
    );
  }
}

export default Main;