import React from "react";
import axios from 'axios';

import ERC20ABI from '../contracts/IERC20Metadata.json'

import ContentHandler from './ContentHandler'
import DateComponent from "../subComponents/DateComponent";
import CopiesProgressBar from "../subComponents/CopiesProgressBar";
import moment from "moment";

class _GameItemHelper {
  buy(component, id, paymentTokenZero, price) {
    
    if(component.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = component.props;
    const { GameForestAssets, ForestStore } = drizzle.contracts;

    var stackId;
    
    if(!paymentTokenZero) {
      stackId = ForestStore.methods.buy.
                  cacheSend( GameForestAssets.address, id);
    }
    else {
      stackId = ForestStore.methods.buy.
                  cacheSend( GameForestAssets.address, id,
                    { from:component.context.activeAccount,
                      to:ForestStore.address, value:price });
    }
    component.setState({ stackId: stackId });
  }

  approve(component, paymentContractAddress, amount) {
    if(component.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }

    const { drizzle } = component.props;
    const { GameForestAssets, ForestStore } = drizzle.contracts;

    if(drizzle.contracts[paymentContractAddress] === undefined) {
      var tokenContract = new component.context.web3.eth.Contract(ERC20ABI.abi,
        paymentContractAddress);
  
      var contractConfig = {
        contractName: paymentContractAddress,
        web3Contract: tokenContract
      };
      var events = [];

      drizzle.addContract(contractConfig, events);
    }

    var stackId;
    
    stackId = drizzle.contracts[paymentContractAddress].methods.approve.cacheSend
        (ForestStore.address, amount, {from: component.context.activeAccount});

    component.setState({ stackId: stackId });
  }

  withdraw(component, nftid, lockedcopies) {
    if(component.context.activeAccount == null) {
      console.log('no active account to withdraw');
      return;
    }

    const { drizzle } = component.props;
    const { GameForestAssets, ForestStore } = drizzle.contracts;

    var stackId;
    
    stackId = ForestStore.methods.withdraw.cacheSend
        (GameForestAssets.address, nftid, lockedcopies,
            {from: component.context.activeAccount});

    component.setState({ stackId: stackId });
  }

  callDownloadAPI(component, nftid, downloadfile) {
    //send to server, wait nonce, sign and resend, wait url, download url
    if(component.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }

    var message = {
      gameID: nftid
    };

    var thisinstance = this;
    axios.post(`${ContentHandler.GetServerBaseURL()}requestdownloadnonce`, message)
    .then(function (response) {

      if(response.status != 200) {
        //handle error
      }

        // Signs the messageHash with a given account
        component.context.web3.eth.personal.sign('0x' + response.data.message,
        component.context.activeAccount, '').then(sig => {
            message = {
              address: component.context.activeAccount,
              nonce: response.data.nonce,
              signature: sig,
              netid: component.context.activeChainId,
            };

            axios.post(`${ContentHandler.GetServerBaseURL()}requestdownloadlink`, message)
              .then(response => {
                
                if(response.status != 200) {
                  //handle error
                }

                thisinstance.downloadFile(component, response.data, downloadfile);
              }).catch(function (error) {
                console.log(error);
                if(error.response !== undefined)
                  console.log(error.response.data);
              });
          });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  downloadFile(component, json, filename) {
    try {
      var downloadLink = `${ContentHandler.GetServerHost()}${json.downloadlink}`;
      var filename = filename;
      const res = axios(
        { url: downloadLink, method: 'GET', responseType: 'blob',
        onDownloadProgress: progressEvent => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          component.setState({downloadProgress: percentage});
        }
      });
      res.then((response) => {
        if (typeof window.navigator.msSaveBlob === 'function') {
          // If it is IE that support download blob directly.
          window.navigator.msSaveBlob(new Blob([response.data], filename));
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
      }).catch(function (error) {
        console.log(error);
        console.log(error.response.data);
      });
    }
    catch(e) {
      console.log(e);
    }
  }

  GetProductDetails(entryid, successcallback, errorcallback) {
    var detailslink = `${ContentHandler.GetServerBaseURL()}product/${entryid}`;
    axios.get(detailslink)
      .then((response) => {
        if(Object.keys(response.data).length == 0) {
          errorcallback(404);
          return;
        }
        successcallback(response.data);
      }).catch(function (error) {
        console.log(error);
        if(error.response !== undefined)
          console.log(error.response.data);
        errorcallback(error);
      });
  }

  GetProductDisplayVariables(saleData, component, paymentTokenSymbol) {
    
    var result = {};

    if(paymentTokenSymbol == null) {
      paymentTokenSymbol = 'Matic'
    }

    var currentTimeStamp = Math.floor(Date.now() / 1000);
    var web3 = component.context.web3;
    var normalPriceWei = web3.utils.toBN(saleData.price);
    var specialPriceWei = web3.utils.toBN(saleData.specialPrice);

    var normalPriceDisplay = web3.utils.fromWei(normalPriceWei, "ether");
    var specialPriceDisplay = web3.utils.fromWei(specialPriceWei, "ether");

    result.saleStarted = currentTimeStamp >= saleData.saleStartDate;
    result.discounted = currentTimeStamp >= saleData.discountStartTimeStamp &&
                      currentTimeStamp <= saleData.discountEndTimeStamp;
    result.unlocked = currentTimeStamp >= saleData.withdrawUnlockDate;

    if(component.context.i18n != null)
      moment.locale(component.context.i18n.languages[0]);

    if(!result.unlocked) {
      var dateString = moment.unix(saleData.withdrawUnlockDate).format("DD MMM YYYY HH:mm ZZ");
      result.unlockWarning =
      (
      <div class="unlockWarning">
        <div>Unlock Warning</div>
      <div>
        After buying you would be able to download and play the game but
        the NFT itself would be locked until {dateString}.
        After that date you would be able to bridge to ethereum and resell the game on opensear or rarible.
      </div>
      </div>
      );

      result.unlockWarningSmall =
      (
      <div class="unlockWarning">
        Trading Unlock on {dateString}.
      </div>
      );
    }
    else {
      result.unlockWarning = '';
    }

    if(!result.saleStarted) {
      result.saleStart = `You can approve the token to prepare for presale. This game's presale is live after:`;
      result.saleStartSmall = `Presale will start after:`;
      result.saleStartCountDown = (
        <div>
          <DateComponent
            countdown={true}
            date={saleData.saleStartDate}>
          </DateComponent>
        </div>
      );
    }
    else {
      result.saleStart = ``;
      result.saleStartCountDown = '';
    }

    if(result.discounted) {
      result.discountedRatio =
        (
        <div class="discountRatio">
          {"-"+new web3.utils.BN(100).sub(specialPriceWei.mul(new web3.utils.BN(100)).div(normalPriceWei)).toString() + "%"}
        </div>
        )
      result.priceDisplay = <div class="normalPriceSlashed">{normalPriceDisplay+paymentTokenSymbol}</div>;
      result.discountedPriceDisplay = <div class="discountedPrice">{specialPriceDisplay+paymentTokenSymbol}</div>;
      result.priceWei = specialPriceWei;
      result.discountWarning = (<div class="discountWarning">Discount ends in:</div>);
      result.discountWarningSmall = result.discountWarning;
      result.discountCountDown = (
        <div class="discountWarningCountDown">
          <DateComponent
            countdown={true}
            date={saleData.discountEndTimeStamp}>
          </DateComponent>
        </div>
      );
    }
    else {
      result.discountedRatio = '';
      result.priceDisplay = <div className="normalPrice">{normalPriceDisplay+paymentTokenSymbol}</div>;
      result.discountedPriceDisplay = '';
      result.priceWei = normalPriceWei;
      result.discountWarning = ``;
      result.discountCountDown = '';
    }

    return result;
  }

  UpdateApprovalAndSymbol(component) {
    const { drizzle } = component.props;

    const paymentContractAddress = component.props.drizzleState.contracts.ForestStore
          ["paymentToken"][component.state.paymentContractAddressDataKey];

    if(paymentContractAddress !== undefined &&
      !component.context.web3.utils.toBN(paymentContractAddress.value).isZero() &&
      component.state.approvedDataKey == null) {
      var tokenAddress = paymentContractAddress.value;

      var tokenContract = new component.context.web3.eth.Contract(ERC20ABI.abi,
        tokenAddress);

      var contractConfig = {
        contractName: tokenAddress,
        web3Contract: tokenContract
      };
      var events = [];

      if(drizzle.contracts[tokenAddress] === undefined)
        drizzle.addContract(contractConfig, events);

      const approvedDataKey = drizzle.contracts[tokenAddress].methods.allowance
            .cacheCall(component.context.activeAccount,
                drizzle.contracts.ForestStore.address);

      const paymentTokenSymbolDataKey = drizzle.contracts[tokenAddress].methods
            .symbol.cacheCall();

            component.setState({approvedDataKey: approvedDataKey,
              paymentTokenSymbolDataKey: paymentTokenSymbolDataKey});
    }
  }

  BuyButton(component, allowanceWei, saleDisplayData,
      buyFunction, approveFunction, paymentTokenSymbol, stock, owned, id,
      paymentContractAddressIsZero) {
    var priceWei = saleDisplayData.priceWei;
    var buttonDisabled = {};
    var buttonCaption = "B u y";

    var outofstock = stock <= 0;

    if(!component.context.web3.utils.isBN(priceWei))
      priceWei = component.context.web3.utils.toBN(priceWei);

    var price = priceWei;

    var priceDisplay = component.context.web3.utils.fromWei(price, "ether");

    var clickFunction = buyFunction;

    var isApproveButton = false;
    if(!paymentContractAddressIsZero) {
      if(!component.context.web3.utils.isBN(allowanceWei))
        allowanceWei = component.context.web3.utils.toBN(allowanceWei);

      var allowance = allowanceWei;

      var allowanceDisplay = component.context.web3.utils.fromWei(allowance, "ether");

      if(allowance.lt(price)) {
        clickFunction = approveFunction;
        buttonCaption = ( <span>A p p r o v e 
                            {" "} {priceDisplay}{" "} 
                            {paymentTokenSymbol}
                          </span>);

        isApproveButton = true;
      }
    }
    
    if(outofstock) {
      buttonDisabled = {disabled: "disabled"};
      buttonCaption = "O u t   o f   S t o c k";
    }

    var alreadyOwnedWarning = '';

    if(owned > 0) {
      alreadyOwnedWarning = (
        <div>{`you already own ${owned} copies`}</div>
      )
    }

    var stockBar = (
      <CopiesProgressBar
        drizzle={component.props.drizzle}
        drizzleState={component.props.drizzleState}
        id={id}
        stock={stock}>

      </CopiesProgressBar>
    )

    var button = (<button
      type="Success"
      className={"btn btn-outline tgfMain"}
      style={{borderWidth: '1px', borderStyle: 'ridge', width: '200px'}}
      onClick={clickFunction}
      {...buttonDisabled}
      >
      <b>{buttonCaption}</b>
    </button>)

    if(!saleDisplayData.saleStarted && !isApproveButton) {
      button = '';
    }

    return (
      <div>
        
        {alreadyOwnedWarning}
      {button}
      <br/>
      <br/>
      {stockBar}
      
      </div>
    );
  }

  WithdrawButton(component, lockedcopies, withdrawFunction, saleDisplayData) {
    var owned = lockedcopies > 0;

    if(!owned || !saleDisplayData.unlocked) {
      return '';
    }

    return (
      <div className="withdrawButtonEnclosure">
        <button
          type="Success"
          className="btn btn-outline tgfMain"
          style={{borderWidth: '1px', borderStyle: 'ridge', width: '200px'}}
          onClick={withdrawFunction}
          >
          <b>Withdraw</b>
        </button>
      </div>
    );
  }

  DownloadButton(component, copies, downloadFunction) {
    var owned = copies > 0;

    if(!owned) {
      return '';
    }

    return (
      <div className="downloadButtonEnclosure">
        <button
          type="Success"
          className="btn btn-outline tgfMain"
          style={{borderWidth: '1px', borderStyle: 'ridge', width: '200px'}}
          onClick={downloadFunction}
          >
          <b>Download</b>
        </button>
      </div>
    );
  }
};

const GameItemHelper = new _GameItemHelper();
export default GameItemHelper;
