import Web3 from "web3";
import GameForestAssets from "./contracts/GameForestAssets.json";
import ForestStore from "./contracts/ForestStore.json";
import AssetsVault from "./contracts/AssetsVault.json";

//import GameForestUsedStoreV0_8_0 from "./contracts/GameForestUsedStoreV0_8_0.json";


const options = {
  web3: {
    block: false,
    customProvider: null,
  },
  contracts: [GameForestAssets, ForestStore, AssetsVault
                /*GameForestUsedStoreV0_8_0*/],
  events: {
    //GameForestAssets: ["TimedCrowdsaleExtended", "TokensPurchased", "RefundsClosed", "RefundsEnabled"],
    //ForestStore: ["TimedCrowdsaleExtended", "TokensPurchased", "RefundsClosed", "RefundsEnabled"],
    //GameForestUsedStoreV0_8_0: ["TimedCrowdsaleExtended", "TokensPurchased", "RefundsClosed", "RefundsEnabled"],
  },
  syncAlways:false,
  init: (drizzleOptions) => {
    var web3;

    if(typeof drizzleOptions.web3.customProvider != "string" &&
    drizzleOptions.web3.customProvider != null) {
      return;
    }

    if (typeof window.ethereum !== 'undefined') {
      // If a web3 instance is already provided by Meta Mask.
      web3 = new Web3(window.ethereum);
    } else {
      // Specify default instance if no web3 instance provided
      web3 = "Press the button below to connect";
    }

    drizzleOptions.web3.customProvider = web3;
  }
};

export default options;
