import React from "react";
import Web3StatusContextProvider from "./contexts/Web3StatusContext";
import { withTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

class NoMatch extends React.Component {
  render() {
    return (
        <div style={{marginLeft:'10px'}}>
            <h2>404Page</h2>
            <p>Redirecting to <a href="/">Home Page</a></p>
        </div>
    );
  }
}

export default withTranslation()(NoMatch);
