import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import logo from "./images/logo.png";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';


import EmergencySuicide from './subComponents/admin/EmergencySuicide'
import AddRevokeRole from './subComponents/admin/AddRevokeRole'
import WhitelistForestAssets from './subComponents/admin/WhitelistForestAssets'
import GameSubmissionForm from './subComponents/admin/GameSubmissionForm'
import PriceSubmissionForm from './subComponents/admin/PriceSubmissionForm'
import PerIDSubmissionForm from './subComponents/admin/PerIDSubmissionForm'
import PrizeSubmissionForm from './subComponents/admin/PrizeSubmissionForm'
import AddPrize from './subComponents/admin/AddPrize'
import DiscountSubmissionForm from './subComponents/admin/DiscountSubmissionForm'
import GetChangeValue from './subComponents/admin/GetChangeValue'
import WithdrawTokens from './subComponents/admin/WithdrawTokens'
import StoreNFTBalance from './subComponents/admin/StoreNFTBalance'
import WithdrawStoreNFTs from './subComponents/admin/WithdrawStoreNFTs'

import { Web3StatusContext } from "./contexts/Web3StatusContext";

const { AccountData, ContractData, ContractForm } = newContextComponents;

class Admin extends React.Component {
  static contextType = Web3StatusContext;

  render() {
    const { activeAccount } = this.context;

    // destructure drizzle and drizzleState from props
    if(activeAccount == null) {
      return (
        <div>Account not connected<br/>
          <button onClick={this.context.connect}>
            Connect Wallet
          </button>
        </div>
      );
    }

    return (
      <div className="App">
        <div>
        <h2>Game Forest Store Address</h2>
        <div>{this.props.drizzle.contracts.ForestStore.address}</div>
        <h2>Game Forest Token</h2>
        <div>{this.props.drizzle.contracts.GameForestAssets.address}</div>
        <h2>Game Form</h2>
        <GameSubmissionForm
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}>
        </GameSubmissionForm>
        </div>

        <div>
        <h2>Price:</h2>
          <PriceSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            >

          </PriceSubmissionForm>
        </div>

        <div>
        <h2>Special Price:</h2>
          <DiscountSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            contractName={"ForestStore"}
            >

          </DiscountSubmissionForm>
        </div>

        <div>
        <h2>Max Supply:</h2>
          <PerIDSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={"maxSupply"}
            setterName={"setMaxSupply"}
            newValueCaption={"MaxSupply"}
            contractName={"GameForestAssets"}
            >

          </PerIDSubmissionForm>
        </div>

        <div>
        <h2>Start Time:</h2>
          <PerIDSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={"saleData"}
            setterName={"setSaleStartDate"}
            getterMember={"saleStartDate"}
            newValueCaption={"New Start Date/Time"}
            contractName={"ForestStore"}
            >

          </PerIDSubmissionForm>
        </div>

        <div>
        <h2>Withdraw unlock date Time:</h2>
          <PerIDSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={"saleData"}
            setterName={"setUnlockDate"}
            getterMember={"withdrawUnlockDate"}
            newValueCaption={"New Withdraw Unlock Date"}
            contractName={"ForestStore"}
            >

          </PerIDSubmissionForm>
        </div>

        <div>
        <h2>Withdraw</h2>
        <WithdrawTokens
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}>
        </WithdrawTokens>
        </div>

        <div>
          <StoreNFTBalance
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            >

          </StoreNFTBalance>
        </div>

        <div>
        <h2>Unstock nfts</h2>
        <WithdrawStoreNFTs
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}>
        </WithdrawStoreNFTs>
        </div>
        
        <div>
        <h2>Add Revoke Minter Role</h2>
        <AddRevokeRole
          role="PREDICATE_ROLE"
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}>
        </AddRevokeRole>
        </div>

        <div>
        <h2>Whitelist forest assets</h2>
        <WhitelistForestAssets
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}>
        </WhitelistForestAssets>
        </div>

        <div>
        <h2>Payment Token</h2>
          <PerIDSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={"paymentToken"}
            setterName={"setPaymentToken"}
            newValueCaption={"paymentToken"}
            contractName={"ForestStore"}
            inputType={"text"}
            >

          </PerIDSubmissionForm>
        </div>

        <div>
        <h2>Assets Contract Owner:</h2>
          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="owner"
            setterName="transferOwnership"
            contractName="GameForestAssets">

          </GetChangeValue>
        <h2>Vault Contract Owner:</h2>
          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="owner"
            setterName="transferOwnership"
            contractName="AssetsVault">

          </GetChangeValue>
        <h2>Store Contract Owner:</h2>
          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="owner"
            setterName="transferOwnership"
            contractName="ForestStore">

          </GetChangeValue>
        </div>

        <div>
        <h2>Product URI</h2>
          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="uri"
            getterParameter={[1]}
            contractName="GameForestAssets">

          </GetChangeValue>

          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="baseURI"
            contractName="GameForestAssets">

          </GetChangeValue>

          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            setterName="setURI"
            contractName="GameForestAssets">

          </GetChangeValue>
        </div>

        <div>
        <h2>Contract URI</h2>
          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="contractURI"
            setterName="setContractURI"
            contractName="GameForestAssets">

          </GetChangeValue>
        </div>

        <hr/>

        <div>
        <h2>Prizes:</h2>
          <h3>Has Prize</h3>
          <PerIDSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={"_hasPrize"}
            setterName={"setHasPrize"}
            inputType={"checkbox"}
            newValueCaption={"New Has Prize"}
            contractName={"ForestStore"}
            useContractAsParam={true}
            >

          </PerIDSubmissionForm>

          <h3>Add Prize</h3>
          <AddPrize
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}>
          </AddPrize>

          <h3>Prize</h3>
          <PrizeSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={"_prizes"}
            setterName={"setPrize"}
            getterMember={["prizeContract", "prizeID"]}
            newValueCaption={"New Prize"}
            contractName={"ForestStore"}
            useContractAsParam={true}
            >

          </PrizeSubmissionForm>

          <h3>Prize Index</h3>
          <PerIDSubmissionForm
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={"_prizeIndex"}
            //setterName={"setSaleStartDate"}
            newValueCaption={"New Prize Index"}
            contractName={"ForestStore"}
            useContractAsParam={true}
            >

          </PerIDSubmissionForm>
        </div>

        <hr/>

        <div>
        <h2>Holder Wallet</h2>
          <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="holderWallet"
            setterName="setHolderWallet"
            contractName="ForestStore">

          </GetChangeValue>
        </div>

        <h4>Emergency Suicide</h4>
        <EmergencySuicide
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}>

        </EmergencySuicide>
      </div>
    );
  };
}

export default Admin;
