import React, {createContext} from 'react';

export const Web3StatusContext = createContext();

class Web3StatusContextProvider extends React.Component {
    state = {
        connected: false,
        activeAccount: null,
        activeChainId: null,
        connect: null,
        web3: null,
        blockNumber: 0,
        web3error: null,
        chainIDerror: null,
        accounterror: null,
        i18n: null,
        serverdata: {},
    }

    network = () => {
        if(this.state.activeChainId != 0)
            return "Main";
    }

    serverInitialized = () => {
        return (
            this.state.serverdata.hasOwnProperty('categories')
        );
    }
    
    setConnected = (connected) => {
        console.log('setConnected');
        this.setState({connected: connected});
    }
    setActiveAccount = (activeAccount) => {
        this.setState(prevState => ({activeAccount: activeAccount}));
        console.log('setActiveAccount');
    }
    setActiveChainId = (activeChainId) => {
        console.log('setActiveChainId');
        this.setState({activeChainId: activeChainId});
    }
    setConnect = (connect) => {
        console.log('setConnect');
        this.setState({connect: connect});
    }
    setWeb3 = (web3) => {
        console.log('state.web3');
        if(typeof this.state.web3 != 'string' && this.state.web3 != null) {
            this.state.web3.eth.clearSubscriptions();
        }

        console.log('setWeb3');
        this.setState({web3: web3});

        web3.eth.subscribe('newBlockHeaders', (err, res, subscription) => {
            if (err) {
                console.log(`Watch error: ${err}`);
            } else {
                this.setState({blockNumber: res.number});
            }
        });
    }
    setWeb3error = (web3error) => {
        console.log('setWeb3error');
        this.setState({web3error: web3error});
    }
    setChainIDerror = (chainIDerror) => {
        console.log('setChainIDerror');
        this.setState({chainIDerror: chainIDerror});
    }
    setAccounterror = (accounterror) => {
        console.log('setAccounterror');
        this.setState({accounterror: accounterror});
    }
    setServerData = (serverdata) => {
        this.setState({serverdata: serverdata});
    }
    seti18n = (i18n) => {
        console.log('seti18n');
        this.setState({i18n: i18n});
    }
    render() { 
        return (
        <Web3StatusContext.Provider value={{...this.state,
                                            network: this.network,
                                            setConnected: this.setConnected,
                                            setActiveAccount: this.setActiveAccount,
                                            setActiveChainId: this.setActiveChainId,
                                            setConnect: this.setConnect,
                                            setWeb3: this.setWeb3,
                                            setWeb3error: this.setWeb3error,
                                            setChainIDerror: this.setChainIDerror,
                                            setAccounterror: this.setAccounterror,
                                            seti18n: this.seti18n,
                                            serverInitialized: this.serverInitialized,
                                            setServerData: this.setServerData,
                                            }}>
            {this.props.children}
        </Web3StatusContext.Provider> );
    }
}
 
export default Web3StatusContextProvider;