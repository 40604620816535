import React from "react";
import axios from 'axios';
import { newContextComponents } from "@drizzle/react-components";
import logo from "./images/logo.png";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';
import { withRouter } from "react-router";
import  { Redirect } from 'react-router-dom';
import { Web3StatusContext } from "./contexts/Web3StatusContext";
import GameItemHelper from './helpers/GameItemHelper'
import NoMatch from './404Page';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import YoutubeSlide from './subComponents/YoutubeSlide';
import DateComponent from './subComponents/DateComponent';
import ContentHandler from './helpers/ContentHandler'
import DownloadProgressBar from './subComponents/DownloadProgressBar'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class Details extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    gameInfo: null,
    typeInfo: null,
    wrongPage: false,
    stackId: 0,
    saleDataKey: null,
    balanceDataKey: null,
    userBalanceDataKey: null,
    lockedUserBalanceDataKey: null,
    paymentContractAddressDataKey: null,
    approvedDataKey: null,
    paymentTokenSymbolDataKey: null,
    downloadProgress: 0,
  };

  componentDidMount() {
    const { drizzle } = this.props;
    const assetContract = drizzle.contracts.GameForestAssets;
    const storeContract = drizzle.contracts.ForestStore;
  
    var typeslug = this.props.match.params.typeslug;
    var entryid = this.props.match.params.entryid;
    var slug = this.props.match.params.slug;
    
    GameItemHelper.GetProductDetails(entryid, (info) => {
      var category = this.context.serverdata.categories.find(o => o.categoryID == info.typeID);
      if(!(info.slug.en == slug && category.slug.en == typeslug)) {
        this.setState({wrongPage: true});
        return;
      }

      this.setState({gameInfo: info, typeInfo: category});
    }, (errorcode) => {
      if(errorcode === 404) {
        this.setState({wrongPage: true});
      }
    });

    const saleDataKey = storeContract.methods.saleData.cacheCall(entryid);
    this.setState({saleDataKey: saleDataKey});

    if(this.context.activeAccount == null) {
      return;
    }

    const userBalanceDataKey = assetContract.methods.balanceOf.cacheCall(
      this.context.activeAccount, entryid);
    const lockedUserBalanceDataKey = storeContract.methods.getVaultBalance.cacheCall(
      assetContract.address, this.context.activeAccount, entryid);

    const paymentContractAddressDataKey = storeContract.methods.
              paymentToken.cacheCall(entryid);

    const balanceDataKey = storeContract.methods.stock.cacheCall(assetContract.address,
      entryid);

    this.setState({ userBalanceDataKey: userBalanceDataKey,
        lockedUserBalanceDataKey: lockedUserBalanceDataKey,
        paymentContractAddressDataKey: paymentContractAddressDataKey,
        balanceDataKey: balanceDataKey });
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    GameItemHelper.UpdateApprovalAndSymbol(this);

    this.lastBlock = this.context.blockNumber;
  }

  buyClicked = (id, paymentTokenZero, price, event) => {
    event.preventDefault();
    
    GameItemHelper.buy(this, id, paymentTokenZero, price);
  }

  approveClicked = (paymentContractAddress, amount, event) => {
    event.preventDefault();
    
    GameItemHelper.approve(this, paymentContractAddress, amount)
  }

  withdrawClicked = (id, lockedcopies, event) => {
    event.preventDefault();
    
    GameItemHelper.withdraw(this, id, lockedcopies)
  }

  downloadClicked = (nftid, event) => {
    event.preventDefault();
    var key = this.props.keyIndex;
    var entryid = this.props.match.params.entryid;
    GameItemHelper.callDownloadAPI(this, entryid, this.state.gameInfo.filename);
  }

   youtubeAutoplayWithCustomThumbs = (materials) => {
    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

    const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

    const customRenderThumb = (children) =>
        children.map((item) => {
          var img = item; // if the item is not an image, try to find the first image in the item's children.
          
          if (item.type !== 'img') {
            img = React.Children.toArray(item.props.children).find(function (child) {
              return child.type === 'img';
            });
          }

          if (!img) {
            const videoId = getVideoId(item.props.url);
            return <img key={videoId} src={getVideoThumb(videoId)} />;
          }
          else {
            return img;
          }
        });

        

    var materialElements = [];

    materials.forEach(element => {
      if(element.includes('.')) {
        var imageurl = ContentHandler.GetImageURL(this.props.match.params.entryid, element);
        var imageCaption = '';//(<p className="legend">Legend 1</p>);
        materialElements.push(
          (
            <div key={`${element}`}>
                <img src={imageurl} />
                {imageCaption}
            </div>
          )
        );
      }
      else {
        materialElements.push(
          (<YoutubeSlide key={`${element}`} url={`https://www.youtube.com/embed/${element}`} />)
        );
      }
    });

    return (
        <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb}>
            {materialElements}
        </Carousel>
    );
  };

  render() {
    if(this.state.wrongPage) {
      return (<NoMatch/>);
    }

    if(this.state.gameInfo == null) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    const GameForestAssets_Contract = this.props.drizzleState.contracts.GameForestAssets;
    const ForestStore_Contract = this.props.drizzleState.contracts.ForestStore;

    var approvedContract = undefined;
    var paymentTokenSymbolContract = undefined;

    const saleDataContract = ForestStore_Contract["saleData"][this.state.saleDataKey];
    const balanceContract = ForestStore_Contract["stock"][this.state.balanceDataKey];
    const userBalanceContract = GameForestAssets_Contract["balanceOf"][this.state.userBalanceDataKey];
    const lockedUserBalanceContract = ForestStore_Contract["getVaultBalance"][this.state.lockedUserBalanceDataKey];
    const paymentContractAddressContract = ForestStore_Contract["paymentToken"][this.state.paymentContractAddressDataKey];

    var paymentContractAddressIsZero = false;
    if(paymentContractAddressContract !== undefined) {
      if(!this.context.web3.utils.toBN(paymentContractAddressContract.value).isZero()) {
        var paymentContract = this.props.drizzleState
                .contracts[paymentContractAddressContract.value];
        if(this.state.approvedDataKey != null) {
          approvedContract = paymentContract["allowance"][this.state.approvedDataKey];
        }
        if(this.state.paymentTokenSymbolDataKey != null) {
          paymentTokenSymbolContract = paymentContract["symbol"][this.state.paymentTokenSymbolDataKey];
        }
      }
      else {
        paymentContractAddressIsZero = true;
      }
    }

    var saleDisplayData = {};
    var paymentTokenSymbol;
    if(saleDataContract !== undefined &&
        (paymentTokenSymbolContract !== undefined || paymentContractAddressIsZero)) {

          
          if(paymentTokenSymbolContract === undefined) {
            paymentTokenSymbol = "Matic";
          }
          else {
            paymentTokenSymbol = paymentTokenSymbolContract.value;
          }

      saleDisplayData = GameItemHelper.
            GetProductDisplayVariables(saleDataContract.value, this,
              paymentTokenSymbol);
    }

    var showButtons = true;

    if(saleDataContract === undefined || balanceContract === undefined ||
      userBalanceContract === undefined ||
      lockedUserBalanceContract === undefined ||
      paymentContractAddressContract === undefined ||
      (!paymentContractAddressIsZero && (approvedContract === undefined ||
      paymentTokenSymbolContract === undefined))) {
        showButtons = false;
    }

    const { activeAccount } = this.context;

    // destructure drizzle and drizzleState from props
    if(activeAccount == null) {
      showButtons = false;
    }

    var entryid = this.props.match.params.entryid;

    var downloadButton = '';
    var buyButton = '';
    var withdrawButton = '';
    var paymentTokenSymbol = '';

    if(showButtons) {
      var activeColor = "#55FF55";
      var nonActiveColor = "#8B8B8B";

      var userBalance = parseInt(userBalanceContract.value);
      var lockedUserBalance = parseInt(lockedUserBalanceContract.value);

      var color = nonActiveColor;

      var allowanceWei = undefined;
      if(approvedContract !== undefined)
        allowanceWei = this.context.web3.utils.toBN(approvedContract.value);

      var buyFunction = this.buyClicked.bind(this, entryid,
          paymentContractAddressIsZero, saleDisplayData.priceWei);
      var approveFunction = this.approveClicked.bind(this,
            paymentContractAddressContract.value, saleDisplayData.priceWei);
      var downloadFunction = this.downloadClicked.bind(this, entryid);
      var withdrawFunction = this.withdrawClicked.bind(this, entryid, lockedUserBalance);

      var copies = userBalance + lockedUserBalance;
      var stock = balanceContract.value;

      if(stock > 0)
        color = activeColor;

      downloadButton = (
      <div>
        {GameItemHelper.DownloadButton(this, copies, downloadFunction)}
        <DownloadProgressBar
          progress={this.state.downloadProgress}
        ></DownloadProgressBar>
      </div>
      );
      
      buyButton = GameItemHelper.BuyButton(this, allowanceWei, saleDisplayData,
        buyFunction, approveFunction, paymentTokenSymbol, stock, copies, entryid, paymentContractAddressIsZero);

        withdrawButton = GameItemHelper.WithdrawButton(this, lockedUserBalance, withdrawFunction, saleDisplayData);
    }

    var typename = this.state.typeInfo.name.en;
    var gametitle = this.state.gameInfo.name.en;
    var gamedescription = this.state.gameInfo.description.en;
    var developer = this.state.gameInfo.developer;

    var typeslug = this.state.typeInfo.slug.en;
    var titleslug = this.state.gameInfo.slug.en;

    var gallery = '';

    gallery = 
      this.youtubeAutoplayWithCustomThumbs(this.state.gameInfo.promotionalMaterial.materials);

    var banner = ContentHandler.GetImageURL(
      entryid, 'banner.jpg');

    var discountedPrice = saleDisplayData.discountedPriceDisplay;
    var discountedRatio = saleDisplayData.discountedRatio;
    var discountWarning = saleDisplayData.discountWarning;
    var discountCountDown = saleDisplayData.discountCountDown;

    var unlockWarning = saleDisplayData.unlockWarning;
    var unlockWarningSmall = saleDisplayData.unlockWarningSmall;

    var saleStart = saleDisplayData.saleStart;
    var saleStartCountDown = saleDisplayData.saleStartCountDown;

    var warning = saleStart;
    var countdown = saleStartCountDown;

    if(saleDisplayData.saleStarted) {
      warning = discountWarning;
      countdown = discountCountDown;
    }

    return (
      <div className="App Details">
        <div className="row">
          <div className="col-md-8 left">
            <div style={{textAlign:"left"}}>
              <small>
                <a href={`/${typeslug}`}>{typename}</a>
                 -> 
                <a href={`/${typeslug}/${entryid}/${titleslug}`}>{gametitle}</a>
              </small>
            </div>
            <div>{gallery}</div>
          </div>
          <div className="col-md-4 right">
            <h2 className="detailsGameTitle">{gametitle}</h2>
            <div className="detailsDeveloperContainer">
            <small>{developer}</small>
            </div>
            <div  className="detailsPriceContainer">
            {discountedPrice} {saleDisplayData.priceDisplay}
            {discountedRatio}
            </div>
            <div>
              <img src={banner} style={{ width: '100%'}} alt={gametitle}/>
              <div className="buttonEnclosure">
                <br/>
              {downloadButton}
              {withdrawButton}
              {warning}
              {countdown}
              {buyButton}
              {unlockWarningSmall}
              </div>
            </div>
            <div className="description">
              {gamedescription}
            </div>
            <div>
            {unlockWarning}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(Details);
