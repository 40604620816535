import React from "react";
import Main from "./Main";
import "./App.css";
import "./bootstrap.min.css";
import './Style.css';
import Web3StatusContextProvider from "./contexts/Web3StatusContext";
import { withTranslation, Trans } from 'react-i18next';

class App extends React.Component {
  

  render() {
    return (
      <Web3StatusContextProvider>
        <Main i18n={this.props.i18n}>
          
        </Main>
      </Web3StatusContextProvider>
    );
  }
}

export default withTranslation()(App);
