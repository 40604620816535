
class _General {
    isEqualArray(array1, array2) {
        if(array1 === undefined && array2 === undefined)
          return true;
        if(array1 === undefined || array2 === undefined)
          return false;
        return array1.length === array2.length && array1.every((value, index) => value === array2[index])
      }
};

const General = new _General();
export default General;
