import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Web3StatusContext } from "../contexts/Web3StatusContext";
import moment from "moment";
import DateCountdown from 'react-date-countdown-timer';
import "moment/locale/ja";
import "moment/locale/tr";

const { AccountData, ContractData, ContractForm } = newContextComponents;

class DateComponent extends React.Component {
  static contextType = Web3StatusContext;

  state = { dataKey: null };

  componentDidMount() {
    if(this.props.contractName === undefined || this.props.contractName.length === 0)
      return;
    const { drizzle } = this.props;
    const contract = drizzle.contracts[this.props.contractName];
  
    // let drizzle know we want to watch the `myString` method
    const dataKey = contract.methods[this.props.dateName].cacheCall(this.props.getterParameter);
  
    // save the `dataKey` to local component state for later reference
    this.setState({ dataKey });
  }
  

  // destructure drizzle and drizzleState from props
  render() {
    var dateValue = this.props.date;
    
    if(!(this.props.contractName === undefined || this.props.contractName.length === 0)) {
      // get the contract state from drizzleState
      var contract = this.props.drizzleState.contracts[this.props.contractName];

      // using the saved `dataKey`, get the variable we're interested in
      const unixdate = contract[this.props.dateName][this.state.dataKey];

      if(this.context.i18n != null)
        moment.locale(this.context.i18n.languages[0]);

      if(unixdate === undefined) {
        return '--';
      }
      dateValue = unixdate.value;
    }
      
    var dateString = '';
    if(this.props.countdown !== undefined && this.props.countdown) {
      dateString = moment.unix(dateValue).format("YYYY MM DD HH:mm ZZ");
      dateString = (<DateCountdown dateTo={dateString} callback={()=>{}} />);
    }
    else {
      dateString = moment.unix(dateValue).format("DD MMM YYYY HH:mm ZZ");
    }
    
    return <div>{dateString}</div>;
  }
};

export default DateComponent;