import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Web3StatusContext } from "../contexts/Web3StatusContext";
import moment from "moment";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import General from '../helpers/General.js'


const { AccountData, ContractData, ContractForm } = newContextComponents;

class CopiesProgressBar extends React.Component {
  static contextType = Web3StatusContext;

  state = {
    maxSupplyDataKey : null,
    totalSupplyDataKey: null,
  };

  componentDidMount() {
    const { drizzle } = this.props;
    const assetContract = drizzle.contracts.GameForestAssets;
    const storeContract = drizzle.contracts.ForestStore;
  
    const maxSupplyDataKey = assetContract.methods["maxSupply"].cacheCall(this.props.id);
    const totalSupplyDataKey = assetContract.methods["totalSupply"].cacheCall(this.props.id);
    
    this.setState({ maxSupplyDataKey, totalSupplyDataKey
      });
  }
  

  render() {
    const { GameForestAssets } = this.props.drizzleState.contracts;

    const maxSupplyContract = GameForestAssets["maxSupply"][this.state.maxSupplyDataKey];
    const totalSupplyDataKey = GameForestAssets["totalSupply"][this.state.totalSupplyDataKey];
    
    if(maxSupplyContract !== undefined && totalSupplyDataKey !== undefined) {
      const maxSupply = parseInt(maxSupplyContract.value);
      const totalSupply = parseInt(totalSupplyDataKey.value);

      var supply = maxSupply;
      if(supply == 0)
        supply = totalSupply;

      var nowPeriod = supply - this.props.stock;

      var variant = "success";
      if(nowPeriod > supply * 2.0 / 3.0) {
        variant = "danger";
      }
      else if(nowPeriod > supply / 3.0) {
        variant = "warning";
      }
      
      var progressBar = <ProgressBar animated min={0} max={supply} now={nowPeriod} variant={variant} />;

      var maxsupplytext = " left";
      if(supply > 0) {
        maxsupplytext = " of " + supply + " left";
      }

      var textElement =
        <small className="justify-content-center d-flex position-absolute w-100 stockProgressText">
          Only {this.props.stock} {maxsupplytext}
        </small>;

      var mainElement =
        <div className="position-relative">
          {textElement}
          
          {progressBar}

        </div>;

      
      return mainElement;
    }
    
    return '--';
  }
};

export default CopiesProgressBar;
