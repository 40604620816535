import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from "../TransactionStatus";
import { Trans } from 'react-i18next';

const { AccountData, ContractData, ContractForm } = newContextComponents;

class GameSubmissionForm extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    gameID: 0,
    amount: 0,
    stackId: -1,
  };
  
  componentDidMount() {
    const { drizzle } = this.props;
    
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    this.lastBlock = this.context.blockNumber;
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    const { GameForestAssets, ForestStore } = this.props.drizzle.contracts;

    var data = this.context.web3.utils.padLeft
                  (this.context.web3.utils.toHex(0), 64);
    
    const stackId = ForestStore.methods.mint.cacheSend(
      GameForestAssets.address,
          this.state.gameID,
          this.state.amount,
          data);
    this.setState({stackId: stackId});
  }

  isNumber(n) {
    return !isNaN(n) && isFinite(n);
  }

  myGameIDChangeHandler = (event) => {
    var gameID = parseInt(event.target.value);
    if (!this.isNumber(gameID)) {
      gameID = this.state.gameID;
    }
    
    this.setState({gameID: gameID});
  }

  myAmountChangeHandler = (event) => {
    var amount = parseInt(event.target.value);
    if (!this.isNumber(amount)) {
      amount = this.state.amount;
    }
    
    this.setState({amount: amount});
  }

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    // get the contract state from drizzleState
    const { GameForestAssets, ForestStore } = this.props.drizzleState.contracts;
    
    var buttonCaption = this.context.i18n.t('payment_form.button_caption');

    var form = '';
    if(this.context.activeAccount == null) {
      form = <span><Trans>payment_form.connect_account</Trans><br/>
              <button onClick={this.context.connect}>
              <Trans>connect_account</Trans>
              </button>
            </span>;
    }
    else {
      var submitButtonEnabled = true;
      
      var increase_text = (<span style={{ color: 'red' }}>
            <Trans>payment_form.increasing_price</Trans>
            </span>);
      increase_text = '';//remove this line for increasing prices
      form = (
        <form onSubmit={this.mySubmitHandler}>
          Game ID: <input
            type="number"
            step={1}
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.gameID}
            onChange={this.myGameIDChangeHandler}
          />
          <br/>
          Amount: <input
            type="number"
            step={1}
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.amount}
            onChange={this.myAmountChangeHandler}
          />
          <br/>
          <input
            type='submit'
            disabled={!submitButtonEnabled}
            value={buttonCaption}
          />
        </form>
      );
    }

    

    return (
      <div><Trans>payment_form.current_price</Trans><br/>
        <br/>
        
        {statusMsg}
        <br/>
        {form}

      </div>
      
    );
  }
};

export default GameSubmissionForm;
