import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import DisplayedWeiData from '../DisplayedWeiData'
import DateComponent from '../DateComponent'
import { Trans } from 'react-i18next';
import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import GetChangeValue from './GetChangeValue'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class WithdrawTokens extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    stackId: -1,
    amount: 0,
    tokenAddress: '0x0000000000000000000000000000000000000000',
    beneficiaryAddress: '0x0000000000000000000000000000000000000000'
  };

  activeAccount = null;

  componentDidMount() {
    const { drizzle } = this.props;
    const contract = drizzle.contracts.ForestStore;
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    const { drizzle } = this.props;
    const contract = drizzle.contracts.ForestStore;

    this.lastBlock = this.context.blockNumber;
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    if(this.context.activeAccount == null) {
      console.log('no active account to approve');
      return;
    }
    const { drizzle } = this.props;
    const { ForestStore } = drizzle.contracts;

    var value = this.state.amount;
    value = this.context.web3.utils.toWei(value.toString(), "ether");

    const stackId = ForestStore.methods.withdrawPayment.cacheSend
                      (this.state.tokenAddress, this.state.beneficiaryAddress,
                        value);
    this.setState({ stackId: stackId });
  }

  myAmountChangeHandler = (event) => {
    let { value } = event.target;
    const amount = value;
    this.setState({amount: amount});
  }

  myTokenAddressChangeHandler = (event) => {
    let { value } = event.target;
    const address = value;
    this.setState({tokenAddress: address});
  }

  myBeneficiaryAddressChangeHandler = (event) => {
    let { value } = event.target;
    const address = value;
    this.setState({beneficiaryAddress: address});
  }

  render() {

    var buttonCaption = 'Withdraw Payment';

    return (
      <div>
        <h3>Withdraw Payment</h3>
        <strong><Trans>withdraw_tokens.can_currently_withdraw</Trans>
        <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName="paymentTokenBalance"
            getterParameter={[this.state.tokenAddress]}
            contractName="ForestStore"
            wei={true}>
        </GetChangeValue> Payment Tokens
        </strong>
          <form onSubmit={this.mySubmitHandler}>
            Payment Token Address:{" "}
            <input
              value={this.state.tokenAddress}
              onChange={this.myTokenAddressChangeHandler}
            />
            <br/>
            Beneficiary:{" "}
            <input
              value={this.state.beneficiaryAddress}
              onChange={this.myBeneficiaryAddressChangeHandler}
            />
            <br/>
            Amount:{" "}
            <input
              value={this.state.amount}
              onChange={this.myAmountChangeHandler}
            />
            <br/>
            <input
            type='submit'
            value={buttonCaption}
            />
          </form>
      </div>
    );
  }
};

export default WithdrawTokens;