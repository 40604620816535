import React from "react";
import { newContextComponents } from "@drizzle/react-components";
import { Accordion, Card, Button, ProgressBar, Table } from 'react-bootstrap';

import { Web3StatusContext } from "../../contexts/Web3StatusContext";
import TransactionStatus from "../TransactionStatus";
import { Trans } from 'react-i18next';
import GetChangeValue from './GetChangeValue'

const { AccountData, ContractData, ContractForm } = newContextComponents;

class PrizeSubmissionForm extends React.Component {
  static contextType = Web3StatusContext;
  lastBlock = 0;

  state = {
    gameID: 0,
    index: 0,
    address: null,
    price: '',
    stackId: -1,
  };
  
  componentDidMount() {
    const { drizzle } = this.props;
    
    this.setState({address: drizzle.contracts.GameForestAssets.address});
  }

  componentDidUpdate() {
    if(this.lastBlock == this.context.blockNumber)
      return;

    this.lastBlock = this.context.blockNumber;
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    var contract = this.props.drizzle.contracts[this.props.contractName];

    var price = this.state.price.toString();
    if(this.props.wei) {
      price = this.context.web3.utils.toWei(price, "ether");
    }
    var stackId;
    if(this.props.useContractAsParam) {
      stackId = contract.methods[this.props.setterName].cacheSend(
        this.props.drizzle.contracts.GameForestAssets.address,
            this.state.gameID,
            this.state.index,
            {prizeContract: this.state.address, prizeID:price});
    }
    else {
      stackId = contract.methods[this.props.setterName].cacheSend(
        this.state.gameID,
        this.state.index,
        price);
    }
    this.setState({stackId: stackId});
  }

  isNumber(n) {
    return !isNaN(n) && isFinite(n);
  }

  myIndexChangeHandler = (event) => {
    var index = parseInt(event.target.value);
    if (!this.isNumber(index)) {
      index = this.state.index;
    }
    
    this.setState({index: index});
  }

  myAddressChangeHandler = (event) => {
    this.setState({address: event.target.value});
  }

  myGameIDChangeHandler = (event) => {
    var gameID = parseInt(event.target.value);
    if (!this.isNumber(gameID)) {
      gameID = this.state.gameID;
    }
    
    this.setState({gameID: gameID});
  }

  myPriceChangeHandler = (event) => {
    var inputType = "number";
    if(this.props.inputType !== undefined) {
      inputType = this.props.inputType;
    }

    if(inputType === "number") {
      var price = parseInt(event.target.value);
      if (!this.isNumber(price)) {
        price = this.state.price;
      }
      this.setState({price: price});
    }
    else {
      if(inputType === "checkbox") {
        this.setState({price: event.target.checked});
      }
      else {
        this.setState({price: event.target.value});
      }
    }
  }

  // destructure drizzle and drizzleState from props
  render() {
    var statusMsg = '';
    if (this.state.stackId >= 0) {
      statusMsg = (
        <TransactionStatus
          drizzle={this.props.drizzle}
          drizzleState={this.props.drizzleState}
          stackId={this.state.stackId}
        >

        </TransactionStatus>
      );
    }

    // get the contract state from drizzleState
    const { GameForestAssets, ForestStore } = this.props.drizzleState.contracts;
    
    var buttonCaption = this.context.i18n.t('payment_form.button_caption');
    var inputType = "number";
    if(this.props.inputType !== undefined) {
      inputType = this.props.inputType;
    }
    var form = '';
    if(this.context.activeAccount == null) {
      form = <span><Trans>payment_form.connect_account</Trans><br/>
              <button onClick={this.context.connect}>
              <Trans>connect_account</Trans>
              </button>
            </span>;
    }
    else {
      var submitButtonEnabled = true;
      
      var increase_text = (<span style={{ color: 'red' }}>
            <Trans>payment_form.increasing_price</Trans>
            </span>);
      increase_text = '';//remove this line for increasing prices

      var button = (<span>
        <input
        type='submit'
        disabled={!submitButtonEnabled}
        value={buttonCaption}
      />
      <br/>
          {this.props.newValueCaption}: <input
            type={inputType}
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.price}
            onChange={this.myPriceChangeHandler}
          />
          <br/>

          prize contract: <input
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.address}
            onChange={this.myAddressChangeHandler}
          />
          <br/>
      </span>);
      if(this.props.setterName === undefined) {
        button = '';
      }

      form = (
        <form onSubmit={this.mySubmitHandler}>
          Game ID: <input
            type="number"
            step={1}
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.gameID}
            onChange={this.myGameIDChangeHandler}
          />
          <br/>
          Index: <input
            type="number"
            step={1}
            /*min={individualMinCapEth}
            max={individualCapEth}*/
            value={this.state.index}
            onChange={this.myIndexChangeHandler}
          />
          <br/>

          {button}
        </form>
      );
    }

    
    var currentCaption = "Current";
    if(this.props.currentCaption !== undefined) {
      currentCaption = this.props.currentCaption;
    }

    var getterParameters = [this.state.gameID, this.state.index];
    if(this.props.useContractAsParam) {
      getterParameters = [this.props.drizzle.contracts.GameForestAssets.address,
        this.state.gameID, this.state.index];
    }

    return (
      <div>{currentCaption}<br/>
        <br/>
        <GetChangeValue
            drizzle={this.props.drizzle}
            drizzleState={this.props.drizzleState}
            getterName={this.props.getterName}
            getterParameter={getterParameters}
            getterMember={this.props.getterMember}
            contractName={this.props.contractName}
            wei={this.props.wei}>
        </GetChangeValue>

        {statusMsg}
        <br/>
        {form}

      </div>
      
    );
  }
};

export default PrizeSubmissionForm;
