import React from "react";
import axios from 'axios';

import ERC20ABI from '../contracts/IERC20Metadata.json'

class _ContentHandler {
    GetServerHost() {
        var host = window.location.protocol + "//" + window.location.host;
        if(host.includes("localhost")) {
            return `http://localhost:9000`;
        }
        return host;
    }

    GetServerBaseURL() {
        return `${this.GetServerHost()}/api/`;
    }

    GetCDNBaseURL() {
        return `${this.GetServerHost()}/promotional_content`;
    }

    GetImageURL(productid, image) {
        return `${this.GetCDNBaseURL()}/${productid}/${image}`;
    }

    GetThumbnailURL(productid) {
        return `${this.GetCDNBaseURL()}/${productid}/thumbnail.png`;
    }
};

const ContentHandler = new _ContentHandler();
export default ContentHandler;
